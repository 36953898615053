
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { codes } from "@/Codes/Codes";
import { useToast } from "vue-toastification";
import { DateHelper } from "@/Utilities/DateHelper";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        startDate: { type: String },
        endDate: { type: String },
        comparisonToggle: { type: Boolean, default: false }
    },
    data() {
        return {
            toast: useToast(),
            loading: false,
            dataSeries: [] as any,
            latestData: [] as any,
            filter: "provider",
            filters: [
                { name: 'Hourly Production', val: 'perHour', selected: true },
                { name: 'Total Production', val: 'moneyEarned' },
                { name: 'Hours Worked', val: 'totalHoursWorked' },
                { name: 'Open time', val: "openTime" },
                { name: 'Raw Hygiene Hourly Production', val: 'hygienePerHour'},
            ],
            chartOptions: {
                colors: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'],
                chart: {
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 3
                },
                tooltip: {
                    shared: false,
                    enabled: true,
                    //intersect:false
                },
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,

                },
                noData: {
                    text: "No data available for selected range",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "black",
                        fontSize: "14px",
                        fontFamily: undefined,
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: "#fff",
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0,
                    },
                    onItemClick: {
                        toggleDataSeries: true,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: [],
                },
            },
            summarySeries: [] as any,
            summaryChartOptions: {
                colors: ['#4363d8', '#000000'],
                chart: {
                    type: 'bar',
                    stacked: false,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                markers: {
                    size: 5,
                },
                noData: {
                    text: "No data available for selected range",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "black",
                        fontSize: "14px",
                        fontFamily: undefined,
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: "#fff",
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0,
                    },
                    onItemClick: {
                        toggleDataSeries: true,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: [],
                },
            }
        };
    },
    methods: {
        filterChange(f) {
            this.bindData(this.latestData, f);
        },
        groupByClinic(data, property) {
            let allDates = [] as any;
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (i == 0) {
                    allDates.push(element.date);
                } else {
                    let flag = true;
                    for (let j = 0; j < allDates.length && flag; j++) {
                        const datesElt = allDates[j];
                        if (datesElt.split("T")[0] == element.date.split("T")[0]) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        allDates.push(element.date);
                    }
                }
            }
            allDates = allDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

            let allClinics = [] as any;
            for (let i = 0; i < data.length; i++) {
                const d = data[i];
                console.log(d);
                if (i == 0) {
                    allClinics.push({ id: d.clinicId, name: `${d.clinicName}` });
                } else {
                    let flag = true;
                    for (let j = 0; j < allClinics.length && flag; j++) {
                        if (allClinics[j].id == d.clinicId) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        allClinics.push({ id: d.clinicId, name: `${d.clinicName}` });
                    }
                }
            }

            let clinicSeriesData = [] as any;

            for (let i = 0; i < allClinics.length; i++) {
                const clinic = allClinics[i];
                let ob = { id: clinic.id, name: clinic.name, data: [] as any } as any;
                allDates.forEach((d) => {
                    ob.data.push(null);
                });
                clinicSeriesData.push(ob);
            }

            for (let i = 0; i < allDates.length; i++) {
                const date = allDates[i].split("T")[0];
                for (let j = 0; j < data.length; j++) {
                    if (data[j].date.split("T")[0] == date) {
                        for (let k = 0; k < clinicSeriesData.length; k++) {
                            const provSer = clinicSeriesData[k];
                            if (provSer.id == data[j].clinicId) {
                                clinicSeriesData[k].data[i] = Math.round(data[j][property]);
                            }
                        }
                    }
                }
            }
            let categoriesToReturn = allDates.map((date) => format(new Date(date), "PP"));
            console.log("clinicSeriesData", clinicSeriesData);
            return { series: clinicSeriesData, categories: categoriesToReturn };
        },
        async getData() {
            this.loading = true;
            try {
                const params = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    compare: this.comparisonToggle
                };
                let res = await ApiService.query("Report/ClinicProductivity", { params });
                console.log(res.data);
                if (res.status === 200) {
                    let data = { 
                        data: res.data.data.map(d => {     
                            d.totalHoursWorked = Math.trunc(d.totalMinutesWorked/60);                        
                            if (d.percentProductiveTime){
                                d.openTime = 100 - (d.percentProductiveTime * 100);  
                            } else {
                                d.openTime = null;
                            }
                            return d;
                        }), 
                        totals: res.data.totals.map(d => {    
                            d.totalHoursWorked = Math.trunc(d.totalMinutesWorked/60);                         
                            if (d.percentProductiveTime){
                                d.openTime = Math.round(100 - (d.percentProductiveTime * 100));  
                            } else {
                                d.openTime = null;
                            }
                            return d;
                        })
                    };                    
                    this.bindData(data, "perHour");
                } else {
                    this.toast.error(codes.error)
                }
            } catch (e) {
                this.toast.error(codes.error)
                throw (e);
            }finally{
                this.loading = false;
            }
        },
        bindData(data: any, property: string) {
            let friendlyName = "N/A";
            if (property === "perHour") {
                friendlyName = "Hourly Production";
            } else if (property === "hygienePerHour") {
                friendlyName = "Raw Hygiene Hourly Production";
            }
            else if (property === "moneyEarned") {
                friendlyName = "Total Production";
            } else if (property === "openTime"){
                friendlyName = "Open Time";
            }

            //grab the clinicID for currentclinic
            let myClinicId = this.$store.getters.currentUser.currentClinic.clinicId;

            this.latestData = data;
            let providerProd = data.data;
            let ans = this.groupByClinic(providerProd, property);
            console.log("ans: ", ans);
            let newOptions = JSON.parse(JSON.stringify(this.chartOptions));
            newOptions.xaxis.categories = ans.categories;
            this.chartOptions = newOptions;
            this.dataSeries = ans.series;


            let summaryData = data.totals.filter(t => t.perHour !== null && t.perHour > 0 && t.totalMinutesWorked > 120);
            const avgValue = parseFloat((summaryData.reduce((acc, curr) => acc + curr[property], 0) / summaryData.length).toFixed(1));
            const averageDataPoint = { clinicName: "Average" };
            averageDataPoint[property] = avgValue;
            let summarizedSummary: any = null;
            if (summaryData.length > 15) {
                DateHelper.sortArrayByProperty(summaryData, property, "desc");
                const topFive = summaryData.slice(0, 5);
                const bottomFive = summaryData.slice(-5);
                summarizedSummary = [...topFive, averageDataPoint, ...bottomFive];

                //grab all the items from the summaryData that match my clinic ID
                const filteredData = summaryData.filter(item => item.clinicId === myClinicId);

                //for each item, check if the item is in the summarized data already (if it is do nothing)
                filteredData.forEach(item => {
                    const isInSummarizedData = summarizedSummary.some(
                        summarizedItem =>
                        summarizedItem.clinicId === item.clinicId &&
                        summarizedItem.providerId === item.providerId
                    );

                    //if it doesn't exist in the summarized data add it to the bottom
                    if (!isInSummarizedData) {
                        summarizedSummary.push(item);
                    }
                 });

                 //re-sort the summarized data descending by property
                 DateHelper.sortArrayByProperty(summarizedSummary, property, "desc");
                 
            } else {
                summarizedSummary = summaryData;
                summarizedSummary.push(averageDataPoint)
                DateHelper.sortArrayByProperty(summarizedSummary, property, "desc");
            }

            let series = summarizedSummary.map(
                t => {
                    if (t.clinicName === "Average") { 
                        return { x: t.clinicName, y: t[property], fillColor: "#A8A8A8" } 
                    } else if (t.clinicId === myClinicId) {
                        return { x: t.clinicName, y: t[property], fillColor: "#4363d8" }
                    } else {
                        return { x: t.clinicName, y: t[property], fillColor: "#B4C6E7" }
                    }                    
                }
            );
            let summaryCategories = summarizedSummary.map(t => `${t.clinicName}`);
            let newSummaryOptions = JSON.parse(JSON.stringify(this.summaryChartOptions));
            newSummaryOptions.xaxis.categories = summaryCategories;
            this.summaryChartOptions = newSummaryOptions;
            this.summarySeries = [{ id: property, name: friendlyName, data: series } as any];

        },
    },

    mounted() {
        this.getData();
    },

    // watch: {
    //     // whenever question changes, this function will run
    //     startDate(oldStartDate, newStartDate) {
    //         this.getProviderData();
    //     },
    // },
});

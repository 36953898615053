
import { defineComponent  } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { AgendaItemDetail, AgendaItem, getProductionCalculationTypeStrategies, EditMode, TimeMode, FeeMode, ProcedureRuleType } 
    from "@/ProcedureRules/ProductionCalculationType";
import { ProcedureCalculator } from '@/components/Calendar/AppointmentComponents/ProcedureCalculator';
import { table } from "table";
import odspCriteria  from '@/ProcedureRules/OdspCriteria.json';
import { IProviderDataContract, IClinicDataContract, ProviderTypeEnum } from "@/Models/data-contract/index";  
import { ElPopover } from 'element-plus';
import AlertComponent from "@/components/atoms/Alert.vue";
import ScalingUnitExplanation from "@/components/Calendar/AppointmentComponents/ScalingUnitExplanation.vue";

interface root {
  showMessage(message);
}

class AdaTeeth{
  adaCode: string;
  teeth: Array<number> | null;
  feeOverride: number | null;  

  constructor(adaCode: string, teeth: Array<number> | null, feeOverride: number | null){
    this.adaCode = adaCode;
    this.teeth = teeth;
    this.feeOverride = feeOverride;
  }
}

interface IAppointmentPayload {
  OrderingDoctor?: string | null,
  ExamDoctor?: string | null,
  Procedures: ProcedurePayload[] 
}

interface ProcedurePayload {
  procedureRuleTypeId : number
  minutes : number
  quantity : number | null
  adaCodes : Array<AdaTeeth> | null
  selectedCriteria: Array<number> | null
}

export default defineComponent({
  emits: ['appointmentChanged', 'editSealants', 'closed', 'saved', 'onToggleDms', 'markedAsCompleted'],
  // emits: {
  //   appointmentChanged(payload: {applicableTime: number, totalProduction: number}) {},
  //   editSealants() {}
  // },  
  components: { ElPopover, AlertComponent, ScalingUnitExplanation },
  data() {
    //const agendaItem = new AgendaItem(5, 5, 5, null);
    //agendaItem.agendaItemDetails.push(new AgendaItemDetail("10111","4", null));


    return {
      appointmentDurationInMinutes: 0 as number,
      appointmentProductionGoalInDollars: 0 as number,
      appointmentProductionInDollars: 0 as number,
      remainingToGoal: 0 as number,
      agendaItems: new Array<AgendaItem>() as Array<AgendaItem>,         
      showDms: true,
      editMode : EditMode.LiveCalculate,
      toast: useToast(),
      EditMode,        
      TimeMode,
      scalingItem: null as AgendaItem | null,
      scalingComponents: new Array<AgendaItem>,
      paItem: null as AgendaItem | null,
      probeItem: null as AgendaItem | null,
      showDentrix: false as boolean,      
      myOdspCriteria: odspCriteria as any,
      doctorsList: [] as { name: string, id: string }[],
      selectedDoctorOrderedForScan: null,
      selectedDoctorOrderedForExam: null,      
      procedureRuleTypeOptionsBackup: null as any//long story      
    };
  },
  props: {
    appointment: { type: Object, required: true },
    procedureRuleTypeOptions: {type: Object, required: true},    
    procedureRuleTypesById: {type: Map<number, ProcedureRuleType>, required: true},
    showToggleDms: {type: Boolean, required: true},
    // permission: { type: Boolean, required: true },
    // earnings: { type: Number, required: true },
    // goal: { type: Number, required: true },
    editable: { type: Boolean, required: true },
    allowSaveAndNext: { type: Boolean, required: false },
    // userPrefs: { type: Object, required: true },
    // commissionGoal: { type: Number, required: true },
    // currentCommission: { type: Number, required: true },
  },
  methods: {    
    onMarkAsCompleted(){
      this.$emit("markedAsCompleted");
    },
    onCloseClicked(){
      this.$emit("closed");
    },
    onSaveClicked(savedAppointment, goNext : boolean){
      this.$emit("saved", {
          date: this.appointment.startDate,
          id: this.appointment.appointmentId,
          rnaTotalPlanned: savedAppointment.rnaTotalPlanned,          
          newReason: savedAppointment.rnaReason || savedAppointment.reason,
          newTotalCompleted: savedAppointment.totalCompleted,
          isOptimal: this.remainingToGoal <= 0,
          goNext: goNext
      });
     // this.$emit("saved");
    },
    onToggleDms(){      
      this.$emit("onToggleDms");
    },
    onTeethClicked(task : AgendaItem){
      console.log("emitting", task)
      this.$emit("editSealants");
    },
    showAgendaItemDetails(item: AgendaItem) : boolean{
      if (!item.agendaItemDetails){
        return false;
      }
      if (item.agendaItemDetails.length === 0){
        return false;
      }
      const itemsOfInterest = item.agendaItemDetails.filter(d => (d.teeth !== null && d.teeth !== "") || (d.description !== null && d.description !== ""));
      if (itemsOfInterest.length === 0){
        return false;
      }
      return true;

    },
    sort(){
      this.agendaItems.sort((a,b) => {
        let ap = this.procedureRuleTypesById.get(a.procedureRuleTypeId);
        let bp = this.procedureRuleTypesById.get(b.procedureRuleTypeId);
        if (ap === undefined || bp === undefined){
          throw new Error("Procedure rule type not found");
        }
        
        let aScheduleOrder = !ap.scheduleOrderBeginning && !ap.scheduleOrderEnd ? 1000 :  (ap.scheduleOrderBeginning ?? 0) + (100000*(ap.scheduleOrderEnd ?? 0))
        let bScheduleOrder = !bp.scheduleOrderBeginning && !bp.scheduleOrderEnd ? 1000 : (bp.scheduleOrderBeginning ?? 0) + (100000*(bp.scheduleOrderEnd ?? 0))            
        return aScheduleOrder - bScheduleOrder;
      })
    },
    getProcedureRuleTypeAndOptions(procedureRuleTypeId: number) {
      let procedureRuleTypeAndOptions : any = null; 
      const matchingProcedureTypes = this.procedureRuleTypeOptions.filter(o => o.procedureRuleTypeId === procedureRuleTypeId)
      if (matchingProcedureTypes.length === 0){
        console.log("using backup option", procedureRuleTypeId);
        procedureRuleTypeAndOptions = this.procedureRuleTypeOptionsBackup[procedureRuleTypeId];
      } else {
        procedureRuleTypeAndOptions = matchingProcedureTypes[0];
      }       
      return procedureRuleTypeAndOptions

    },
    recalculate(){
      const c = new ProcedureCalculator();
      for(let item of this.agendaItems){
        if (item.minutes === -1){          
          item.minutes = this.calculateTimeFromFee(item);
        }
        // if (item.timeMode === TimeMode.CalculateFromFee){
        //   item.minutes = this.calculateTimeFromFee(item);
        // }

        //fee and commission
        if (item.feeMode === FeeMode.CalculatePerMinute ){
          const procedureRuleTypeAndOptions = this.getProcedureRuleTypeAndOptions(item.procedureRuleTypeId);          
          let proc = c.calculateProceduresFromMinutes(procedureRuleTypeAndOptions.procedureOptions, item.minutes, true);   
          item.maxMinutes = proc.maxMinutes;          
          item.fee = proc.fee;
          item.providerCommission = proc.commission; 
          item.agendaItemDetails = new Array<AgendaItemDetail>();
          for(const adaCode of proc.adaCodes){
            item.agendaItemDetails.push(new AgendaItemDetail(adaCode, null, null))
          }          
        } else if (item.feeMode === FeeMode.CalculatePerUnitOfTime){          
          const procedureRuleTypeAndOptions = this.getProcedureRuleTypeAndOptions(item.procedureRuleTypeId);          
          let proc = c.calculateProceduresFromMinutes(procedureRuleTypeAndOptions.procedureOptions, item.minutes, false);   
          item.maxMinutes = proc.maxMinutes;
          item.fee = proc.fee;
          item.providerCommission = proc.commission; 
          item.agendaItemDetails = new Array<AgendaItemDetail>();
          for(const adaCode of proc.adaCodes){
            item.agendaItemDetails.push(new AgendaItemDetail(adaCode, null, null))
          }          
        } else {

        }
        
        if (!this.scalingItem && item.procedureRuleTypeId === 18){
          this.scalingItem = item;
        }

        if (!this.paItem && item.procedureRuleTypeId === 35){
          this.paItem = item;
        }

        if (!this.probeItem && item.procedureRuleTypeId === 10){
          this.probeItem = item;
        }
      }

      if (this.paItem){
        //this.paItem.minutes = 5;
        let totalPas = 0
        this.paItem.childrenAgendaItems.length = 0; //clear the array and refil
        for(const paChild of this.agendaItems.filter(a => this.procedureRuleTypesById.get(a.procedureRuleTypeId)!.calculationType === 7)){          
          if (paChild !== this.paItem){
            totalPas += paChild.quantity ?? 0;
            paChild.minutes = 0;
            this.paItem.childrenAgendaItems.push(paChild);
            paChild.show = false;
          }
        }
        
        if (this.paItem.childrenAgendaItems.length > 0){
          const paOption = (this.getProcedureRuleTypeAndOptions(35)).procedureOptions.find(p => p.units === totalPas);
          let charge = paOption.ammount
          this.paItem.fee = charge;
          this.paItem.providerCommission = charge * paOption.commissionPercentage; 
          //this.paItem.minutes = this.calculateTimeFromFee(this.paItem);
        }        
      }

      if (this.probeItem){ //i have to find its parent
        const examIds= [2,17,23,24,25,26,28];
        let probeParent = null as AgendaItem | null;
        for(const item of this.agendaItems){
          if (examIds.includes(item.procedureRuleTypeId)){
            //we will just take the first one
            probeParent = item;
            probeParent.childrenAgendaItems.length = 0;
            probeParent.childrenAgendaItems.push(this.probeItem);
            this.probeItem.show = false;
            break;
          }
        }
      }
      

      if (this.scalingItem){
        this.scalingComponents.length = 0;  
        this.scalingItem.minutes = this.getRemainingTime(this.scalingItem);
        console.log("minutes", this.scalingItem.minutes);
        let scalingTimeForCharge = this.scalingItem.minutes;
        let scalingItemProcedureRuleTypeId = this.scalingItem.procedureRuleTypeId;
        for(const item of this.agendaItems){
          const procedureRuleType = this.procedureRuleTypesById.get(item.procedureRuleTypeId)!;
          if (procedureRuleType.calculationType === 4 && item !== this.scalingItem) {
            scalingTimeForCharge += Number(item.minutes);
            this.scalingComponents.push(item);
          }
          if (item === this.scalingItem) {
            this.scalingComponents.push(this.scalingItem);
          }
        }        
        const r = this.getProcedureRuleTypeAndOptions(scalingItemProcedureRuleTypeId);
				let proc = c.calculateProceduresFromMinutes(r.procedureOptions, scalingTimeForCharge, false);
        
        this.scalingItem.fee = proc.fee;
        this.scalingItem.providerCommission = proc.commission;
        this.scalingItem.agendaItemDetails = new Array<AgendaItemDetail>();
        for(const adaCode of proc.adaCodes){
          this.scalingItem.agendaItemDetails.push(new AgendaItemDetail(adaCode, null, null))
        }
      }

      let appointmentProductionInDollars = 0;
      let totalProviderCommission = 0;
      for(let item of this.agendaItems){
        appointmentProductionInDollars += item.fee ?? 0;
        totalProviderCommission += item.providerCommission ?? 0;
      }
      
      this.appointmentProductionInDollars = appointmentProductionInDollars;
      this.remainingToGoal = this.appointmentProductionGoalInDollars - this.appointmentProductionInDollars;
      console.log("items",this.agendaItems);
      console.log("Production", appointmentProductionInDollars);
      console.log("totalProviderCommission", totalProviderCommission);
      this.$emit('appointmentChanged', {
        applicableTime: this.getApplicableTime(), 
        appointmentProductionInDollars: appointmentProductionInDollars, 
        selectedProcedureTypeIds: this.agendaItems.map(a => a.procedureRuleTypeId),  
        totalProviderCommission: totalProviderCommission    
      })
    },
    addItem(procedure){
      console.log("adding procedure", procedure);
      for(const s of getProductionCalculationTypeStrategies()){
        for(const item of s.getAgendaItemsFromSavedProcedures(this.procedureRuleTypesById, Array(procedure))){
          const exitingItem = this.agendaItems.find(a => a.procedureRuleTypeId === item.procedureRuleTypeId)
          if (exitingItem){
            const index = this.agendaItems.indexOf(exitingItem);
            this.agendaItems.splice(index, 1);
          }          
          this.agendaItems.push(item);
        }
      }

      this.sort();

      this.recalculate();
    },
    removeItem(agendaItem: AgendaItem){
      const index = this.agendaItems.indexOf(agendaItem);
      if (index > -1) { // only splice array when item is found
        this.agendaItems.splice(index, 1); // 2nd parameter means remove one item only
      }

      //remove children of item if any
      for(const child of agendaItem.childrenAgendaItems){
        const index = this.agendaItems.indexOf(child);
        if (index > -1) { // only splice array when item is found
          this.agendaItems.splice(index, 1); // 2nd parameter means remove one item only
        }
        if (child === this.probeItem){
          this.probeItem = null;
        }
      }

      //if its PA, have to remove all children as well
      if (agendaItem === this.paItem){        
        this.paItem = null;
      }
      this.recalculate();
    },
    removeItemByTypeId(procedureRuleTypeId: number){
      const items  = this.agendaItems.filter(a => a.procedureRuleTypeId === procedureRuleTypeId)
      if (items.length > 0)
      {
        const item = items[0];
        this.removeItem(item);
      }
    },
    changeMinutes : function (event, task : AgendaItem) {
      
      task.minutes = Number(event.target.value);
      this.recalculate();
    },
    getApplicableTime(): number{
      let minutes = 0 as number;      
      for(const item of this.agendaItems){        
        const procRuleType = this.procedureRuleTypesById.get(item.procedureRuleTypeId)!;
        if (!procRuleType.isOutsideAppointment && !procRuleType.isWithScaling){
          minutes = minutes + item.minutes;
        }
      }            
      return minutes;

    },
    //gets the remaining time 
    getRemainingTime(agendaItem : AgendaItem){      
      
      let remainingMinutes = 0 as number;
      for(const item of this.agendaItems?.filter(a => a !== agendaItem)){        
        const procRuleType = this.procedureRuleTypesById.get(item.procedureRuleTypeId)!;
        if (!procRuleType.isOutsideAppointment && !procRuleType.isWithScaling){
          remainingMinutes = remainingMinutes + item.minutes;
        }
      }            
      return Math.max(0, Math.min(77,this.appointmentDurationInMinutes - remainingMinutes)); //cap it at 77 but of course never less than 0
    },

    calculateTimeFromFee(agendaItem : AgendaItem | any){   
      let totalMinutes = (agendaItem.fee / this.appointmentProductionGoalInDollars) * this.appointmentDurationInMinutes;    
      totalMinutes = Math.round(totalMinutes);
      return totalMinutes;
    },    
    formatTime(minutes: number){
      return minutes;
      const padTime = n => ("" + n).padStart(2, '0');
      const secondsToMinSec = time => `${padTime(~~(time / 60))}:${padTime(Math.ceil(time - ~~(time / 60) * 60))}`
      return secondsToMinSec(minutes*60);
    },
    copyServicesMinified() {
				let tablex = [] as any[];
				for (const agendaItem of this.agendaItems) {					
          let body = {
            code: agendaItem.agendaItemDetails.map(d => d.adaCode).join(", "),
            service: `${agendaItem.name}`,
            selectedService: agendaItem.agendaItemDetails.length > 0 ? `(${agendaItem.agendaItemDetails[0].description})` : '',
            ammount: agendaItem.fee === null ? "" : agendaItem.fee == 0 ? "" : agendaItem.fee?.toFixed(0),
            time: `${agendaItem.minutes.toFixed(0)}`,
          };          
          tablex.push(`${body.code ? body.code + ' - ' : ""}${body.service}${(body.time ?? "0") === "0" ? "" : ", " + body.time + " min" }${body.ammount ? ", " + body.ammount + "$" : ""}`);					
				}				
				let finalString = "********** RNA NOTES **********";
				for (let i = 0; i < tablex.length; i++) {
					const row = tablex[i];
					finalString += `\n${row}`;
				}
				console.log(finalString);
				navigator.clipboard.writeText(finalString);
				((this.$root as unknown) as root).showMessage("copied to clipboard!");
			},

    copyServices() {
      let tablex = [["Code", "Service", "Minutes", "Charge"]];
      let services = [] as any[];
      let completedStrings = [] as any[];
      for (const agendaItem of this.agendaItems) {					
        let body = {
          code: agendaItem.agendaItemDetails.map(d => d.adaCode).join(", "),
          service: `${agendaItem.name}`,
          selectedService: agendaItem.agendaItemDetails.length > 0 ? `(${agendaItem.agendaItemDetails[0].description})` : '',
          ammount: agendaItem.fee === null ? "" : agendaItem.fee == 0 ? "" : agendaItem.fee?.toFixed(0),
          time: `${agendaItem.minutes.toFixed(0)}`,
        };
            services.push(body);
            completedStrings.push(`${body.code}\t ${body.service} ${body.selectedService} \t ${body.time} \t ${body.ammount}`);
            tablex.push([body.code, `${body.service} ${body.selectedService}`, body.time, body.ammount]);
      }

      console.log(services, completedStrings);
      navigator.clipboard.writeText(table(tablex));
      ((this.$root as unknown) as root).showMessage("copied to clipboard!");
    },
    async saveOpted(goNext) {
				(this.$refs.saveButton as any).disabled = true;
				(this.$refs.saveButton as any).setAttribute("data-kt-indicator", "on");
				const procedurePayload = new Array<ProcedurePayload>();
				for(const agendaItem of this.agendaItems){
          let fee = null as number | null;
          if (agendaItem.feeMode === FeeMode.CalculatePerMinute){
            fee = agendaItem.fee;
          }
          procedurePayload.push({
            procedureRuleTypeId: agendaItem.procedureRuleTypeId,                
            adaCodes: agendaItem.agendaItemDetails.map<AdaTeeth>(d => new AdaTeeth(d.adaCode ?? "", [Number(d.teeth)],fee ?? d.feeOverride)),
            quantity: agendaItem.quantity,
            minutes: agendaItem.minutes,
            selectedCriteria: agendaItem.criteria
          })
        }
        const appointmentPayload = {
          ExamDoctor: this.selectedDoctorOrderedForExam,
          OrderingDoctor: this.selectedDoctorOrderedForScan,
          Procedures: procedurePayload
        } as IAppointmentPayload;

				const apptId = this.appointment.appointmentId;
				try{
          const res = await ApiService.postData(
           `/Appointment/${apptId}`, appointmentPayload, {});
          if (res.status == 200) {
            console.log("result data", res.data);
            // this.showSave();
            // Should close the Modal ?
            (this.$refs.saveButton as any).disabled = false;
				    (this.$refs.saveButton as any).setAttribute("data-kt-indicator", "off");
            this.onSaveClicked(res.data, goNext);
            //this.close();
          } else {
            this.toast.error(codes.error);
          }
        }catch (e) {
          this.toast.error(codes.error);
          throw e;
        }
    },
    async getDoctors() {
      try{
        const params = {
					active:true, 
          providerType:ProviderTypeEnum.Doctor
				};
        let res = await ApiService.query("Provider", { params }); 
        if (res && res.data) {
          const providerData = res.data as IProviderDataContract[];
          const tempDoctorsList: { name: string, id: string }[] = [];
          providerData.forEach((element) => {            
              tempDoctorsList.push({ name: element.first_name.trim() + ' ' + element.last_name.trim(), id: element.provider_id });            
          });
          tempDoctorsList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          this.doctorsList = tempDoctorsList;
        }
        if(res.status !== 200){
          this.toast.error(codes.error);
        }
      }catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
  },
  computed: {
    
  },
  async mounted() {
     try {
     
        
        this.agendaItems = Array<AgendaItem>(); 
        
          this.appointmentDurationInMinutes = this.appointment.durationInMinutes;
          this.appointmentProductionGoalInDollars = this.appointment.providerAppointmentGoal;
          for(const s of getProductionCalculationTypeStrategies()){
            this.agendaItems.push(...s.getAgendaItemsFromSavedProcedures(this.procedureRuleTypesById, this.appointment.selectedRecommendations))            
          }

          let itemsMissingOptions = this.agendaItems.filter(i => this.procedureRuleTypeOptions.filter(
            o => o.procedureRuleTypeId === i.procedureRuleTypeId).length === 0);
          if (itemsMissingOptions.length > 0){
            console.log("getting missing options");
            const params = { feeScheduleId:this.appointment.feeScheduleId};
            let res = await ApiService.query("ProcedureRules/RuleTypesAndOptions", { params });
            this.procedureRuleTypeOptionsBackup = Object.assign({}, ...res.data.map((p) => ({[p.procedureRuleType.procedureRuleTypeId]: p})));            
          }

          this.sort();
          this.recalculate();
          // Set Ordering doctor
          if (this.appointment.orderingDoctor) {
            this.selectedDoctorOrderedForScan = this.appointment.orderingDoctor.providerId;
          }
          // Set Exam doctor
          if (this.appointment.examDoctor) {
            this.selectedDoctorOrderedForExam = this.appointment.examDoctor.providerId;
          }
        }
      catch (e) {
          this.toast.error(codes.error);
          throw e;
          
      }
      //add some fun data. for another day
      // Get List of Doctors
      await this.getDoctors();       
  },  
});

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AssessmentReport from "../views/AssesmentReport.vue";
import MyDashboard from "../views/MyDashboard.vue";
import OpenTimeDashboard from "../views/OpenTimeDashboard.vue";
import InsightsCalendar from "../components/Calendar/InsightsCalendar.vue";
import Users from "../components/settings/Users.vue";
import store from '../store/index'
import Profile from '@/components/Profile/Profile.vue';
import Error500 from "@/views/error/Error500.vue"
import Login from "@/views/Login.vue"
import { defineComponent } from "vue";
import { getInstance } from "@/auth/auth"
import { useAuth0 } from "@/auth/auth";
import { Actions } from "@/store/enums/StoreEnums";
import Settings from "@/components/settings/Settings.vue"
import BillingVisualization from "@/components/Charts/BillingVisualization.vue"
import DailyVisualizations from "@/components/Charts/DailyVisualizations.vue"
import PersonalCalendarController from "@/components/Calendar/PersonalCalendarController.vue"
import Providers from "@/components/settings/Providers.vue"
import Payroll from "@/components/Reports/Payroll.vue"
// import Payroll from "@/components/Payroll.vue"
import ProductivityReport from "@/components/Reports/ProductivityReport.vue"
import AppointmentController from "@/components/Calendar/AppointmentController.vue"
import NewNote from "@/views/NewNote.vue"
import SqlExplorer from "@/components/CodeEditor/SqlExplorer.vue"
import ProductivityReportByDay from "@/components/Reports/ProductivityReportByDay.vue"
import UsageReport from "@/components/Reports/UsageReport.vue";
import NoClinicsError from "@/views/NoClinicsError.vue"
import YoY from "@/components/Charts/YoY.vue"
import DrYoY from "@/components/Charts/DrYoY.vue"
import OtherReports from "@/components/Reports/OtherReports.vue"
import DataExplorer from "@/views/DataExplorer.vue"
import GenerateChart from "@/views/GenerateChart.vue"


const authenticationGuard = (to, from, next) => {
  console.log("guard checking to", to)
  console.log("guard checking from", from)
  console.log("guard checking next", next)
  console.log("guard checking currentuser", store.getters.currentUser)

  if (to.name !== 'login' && !store.getters.currentUser) {
    next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }
  else {
    next()
  }
};

const adminGuard = (to, from, next) => {
  const isAdmin = store.getters.currentUser.currentClinic.isAdmin
  if (isAdmin) {
    next();
  }
  else {
    return next(from);
  }
};

const payrollGuard  = (to, from, next) => {
  const isAdmin = store.getters.currentUser.currentClinic.isPayrollReportVisible
  if (isAdmin) {
    next();
  }
  else {
    return next(from);
  }
};


const superAdminGuard = (to, from, next) => {
  const isAdmin = store.getters.currentUser.isSuperAdmin;
  if (isAdmin) {
    next();
  }
  else {
    return next(from);
  }
};

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/mydashboard",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: authenticationGuard,
    children: [      
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/reports/assessment",
        name: "Assessment",
        component: AssessmentReport,
        beforeEnter: superAdminGuard,
      },
      {
        path: "/mydashboard",
        name: "mydashboard",
        component: MyDashboard,
      },
      {
        path: "/openTimeDashboard",
        name: "openTimeDashboard",
        component: OpenTimeDashboard,
      },
      {
        path: "/insights",
        name: "Insights",
        component: InsightsCalendar,
      },
      {
        path: "/appointment/:id",
        name: "appointment",
        component: AppointmentController,
      },         
      {
        path: "/personalCalendar",
        name: "PersonalCalendarController",
        component: PersonalCalendarController,
      },
      {
        path: "/reports/productivityreport",
        name: "ProductivityReport",
        component: ProductivityReport,
      },
      {
        path: "/reports/productivityreportbyday",
        name: "ProdReportByDay",
        component: ProductivityReportByDay,
      },
      {
        path: "/reports/usagereport",
        name: "UsageReport",
        component: UsageReport
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "/charts/productivity",
        name: "BillingVisualization",
        component: BillingVisualization,
        beforeEnter: adminGuard
      },
      {
        path: "/charts/dailies",
        name: "DailyVisualizations",
        component: DailyVisualizations,
        beforeEnter: adminGuard
      },
      {
        path: "/charts/yoy",
        name: "yoy",
        component: YoY,
        //beforeEnter: adminGuard
      },
      {
        path: "/charts/dryoy",
        name: "dryoy",
        component: DrYoY,
        beforeEnter: adminGuard
      },
      {
        path: "/settings/users",
        name: "Users",
        component: Users,
        beforeEnter: adminGuard
      },   
      {
        path: "/settings/providers",
        name: "Providers",
        component: Providers,
        beforeEnter: adminGuard
      },  
      {
        path: "/settings/sqlExplorer",
        name: "SqlExplorer",
        component: SqlExplorer,
        beforeEnter: superAdminGuard,
      },
      {
        path: "/reports/payroll",
        name: "Payroll",
        component: Payroll,
        beforeEnter: payrollGuard,
      }, 
      {
        path: "/Reports/OtherReports",
        name: "OtherReports",
        component: OtherReports,
        beforeEnter: adminGuard
      }, 
      {
        path: "/DataExplorer",
        name: "DataExplorer",
        component: DataExplorer,
        beforeEnter: superAdminGuard
      },
    ]

  },  
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/generatechart",
    name: "Generate Chart",
    component: GenerateChart,
  },
  {
    path: "/appointment/:id/NewNote",
    name: "Note",
    component: NewNote,
    beforeEnter: authenticationGuard,
  },   
  {
    path: "/logout",
    name: "logout",
    component: defineComponent({
      async mounted() {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        localStorage.removeItem("ClinicUserToken");
        if (store.getters.currentUser) {
          await getInstance().logout({ returnTo: window.location.origin });
        } else {
          const domain = "dev-qpn294pj.us.auth0.com";
          const clientId = "z9TMAZxs1GANE869RHEvEQgGXO62EHgK";
          const audience = "https://api.rna180.ca";

          const auth0 = await useAuth0({
            domain,
            clientId,
            audience,
            redirectUri: `${window.location.origin}/login`,
            onRedirectCallback: (appState) => {
              console.log("appstate", appState);
              this.$router.push(
                appState && appState.targetUrl
                  ? appState.targetUrl
                  : window.location.pathname
              );
            },
          });
          await auth0.logout({ returnTo: window.location.origin });
        }
      }
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/noclinicerror",
    name: "noclinicerror",    
    component: NoClinicsError,
  },
  {
    path: "/500",
    name: "Internal Error",
    component: Error500,
  },



];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.currentUser) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;


import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Avatar from "@/components/Avatar.vue";
import { LOCAL_STORAGE_CONST } from "@/Models/local-storage.model";

export default defineComponent({
  name: "kt-user-menu",
  components: {Avatar},
  data(){
    return {
      clinicNameFilter: null,
    }
  },
  computed:{
    filteredClinics(): any {
      if(this.clinicNameFilter && this.clinicNameFilter != ""){
        return this.searchForClinic(this.clinicNameFilter).sort((a, b) => a.name.localeCompare(b.name));
      }else{
        return this.currentUser.clinics.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
  },
  methods: {
    async switchClinic(clinicGuid){
      localStorage.setItem(LOCAL_STORAGE_CONST.ClinicGuid, clinicGuid);
      if (this.$router.currentRoute.value.path.includes('/appointment/')) {
        window.location.assign('/dashboard')
         return;
      } 
      window.location.reload();
    },
    logOut(hint_email) {
      this.$router.push("/logout")

      // this.$msalInstance.logoutRedirect()
      //   .then(() => {
      //     this.$emit('logout', 'logging out');
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },
    searchForClinic(e){
      let targetWords = e;
      let filtered = this.currentUser.clinics.filter(x=> x.name.toLowerCase().includes(targetWords.toLowerCase()));
      console.log(filtered)
      return filtered;
    },
  },  
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    i18n.locale.value = localStorage.getItem(LOCAL_STORAGE_CONST.lang)
      ? (localStorage.getItem(LOCAL_STORAGE_CONST.lang) as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = lang => {
      localStorage.setItem(LOCAL_STORAGE_CONST.lang, lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      currentUser,
    };
  }
});

<template>
	<div class="row gy-5 g-xl-8 mb-5">
		<div class="col-6">
			<h1>Good morning, {{ userFirstName }}.</h1>
			<h6>Let's see how your hygiene program is doing today. <sup><i class="fa-solid fa-circle-info text-primary" :title="`Dashboard is currently reflecting ${providersReflectedDescription}`"></i></sup></h6>
		</div>
		<div class="col-6 text-end" style="align-content: end;" v-if="providerList.length > 2">
			<span class="fs-5 pe-4 text-nowrap">Provider:</span>       
			<select id="providerList" class="form-select d-inline-block" style="width:auto" aria-label="Default select example" @change="providerChanged" v-model="selectedProvider">			
				<option v-for="(provider, index) in providerList"
					:key="index"
					:value="provider.id">
					{{ provider.id === "0" ? "" : `${provider.id} - `}}{{ provider.name }}
				</option>
			</select>
		</div>

	</div>
	<div class="row gy-5">
		<div class="col-6 col-lg-3">
			<router-link to="/dashboard" class="card-xl-stretch mb-xl-8  card hoverable h-100 " :class="{ redclass: this.todayforcasted < this.clinicGoal, greenclass: this.todayforcasted >= this.clinicGoal }">
				<div class="card-body row m-0 px-5">
					<div class="col-5 customSvg px-2">
						<inline-svg src="/media/svg/dashboard/ForcastedPerHour.svg" width="100%" />
					</div>
					<div class="col-7">
						<p class="fw-bolder forcasted text-nowrap">{{ todayforcasted }}$</p>						
						<p class="fw-bold fs-5  forcastedText">Today's Forcasted per hour</p>
					</div>
				</div>
			</router-link>
		</div>
		<div class="col-6 col-lg-3">
			<router-link to="/dashboard" class="card-xl-stretch mb-xl-8 bg-white card hoverable h-100">
				<div class="card-body row m-0 px-2">
					<div class="col-5 customSvg">
						<inline-svg src="/media/svg/dashboard/AppointmentsNeedingImprovement.svg" width="100%" />
					</div>
					<div class="col-7">
						<p class="fw-bolder needImprovments">{{ numberOfAppts }}</p>
						<p class="text-inverse-white fw-bold fs-5 m-0">Appts. needing improvement</p>
					</div>
				</div>
			</router-link>
		</div>
		<div class="col-6 col-lg-3">
			<router-link to="/dashboard" class="card-xl-stretch mb-xl-8 bg-white card hoverable h-100">
				<div class="card-body row m-0 px-2">
					<div class="col-5 customSvg">
						<inline-svg src="/media/svg/dashboard/PerfectAppointment.svg" width="100%" />
					</div>
					<div class="col-7">
						<p class="fw-bolder perfectAppts">{{ numberOfPerfectAppts }}</p>
						<p class="text-inverse-white fw-bold fs-5 m-0">Perfect appointments</p>
					</div>
				</div>
			</router-link>
		</div>
		<div class="col-6 col-lg-3">
			<div class="card-xl-stretch mb-xl-8 bg-white card hoverable h-100" title="Percentage of empty time between appointments. Lower is better. Negative value means overlapping bookings">
				<div class="card-body row m-0 px-5">
					<div class="col-5 customSvg">
						<i class="fa-regular fa-clock" style=" font-size: 60px;padding-top: 5px;" ></i>
					</div>
					<div class="col-7">
						<div :style="`color:${getOpenTimeColor(todaysOpenTime)};padding-top:17px;line-height:25px`" class="fw-bolder opentime">
							
								{{ todaysOpenTime  !== null ? todaysOpenTime + '%' : 'N/A'}}
							
							
						</div>
						<span class="fs-7" :style="`margin-top: -10px;color:${getOpenTimeColor(todaysOpenTime)}`">
								{{ todaysOpenTimeHours  !== null ? todaysOpenTimeHours + ' hrs.' : 'N/A'}}
							</span>
						<p class="text-inverse-white fw-bold fs-5 m-0">Open time</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-5">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<h1>Forecasted Per Hour Production</h1>
					<apexchart width="100%" height="300px" type="bar" :options="chartOptions" :series="series" :xaxis="xaxis"></apexchart>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-5">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<h1>Forecasted Open time Percentage</h1>
					<apexchart v-if="openTimeChartOptions" width="100%" height="300px" type="bar" :options="openTimeChartOptions" :series="openTimeSeries" :xaxis="xaxis"></apexchart>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import { useStore } from "vuex";
	import { DateHelper } from "@/Utilities/DateHelper"
	export default defineComponent({
		name: "kt-widget-9",
		components: {},
		props: {
			widgetClasses: String,
		},
		data: function() {
			return {
				//hack: needed for the case where you login not as an admin but as a provider that doesn't have a provider assigned
				allProvidersList: [], 
				providersReflectedDescription: "\'All\'",
				userFirstName: "",
				clinicGoal: 0,
				providerList: [],
				selectedProvider: "0",
				todayforcasted: 0,
				oldTodayforcasted: 0,
				numberOfAppts: 0,
				numberOfPerfectAppts: 0,
				todaysOpenTime: null,
				todaysOpenTimeHours: null,
				latestData: [],
				calculatedSeries: [],
				calculatedPercentage: 0,
				series: [],
				openTimeSeries: [],
				xaxis: {
					type: "category", //["Mon", "Tues", "Wed", "Today", "Tomorrow"]
				},
				chartOptions: {
					//colors: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'],
					chart: {
						type: "bar"
					},
					toolbar: {
						show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
						},
					},
					yaxis: {
						max: 500,
					},
					grid: {
						show: true,
						//borderColor: '#181C32',
						strokeDashArray: 3,
						yaxis: {
							lines: {
								show: true,
							},
						},
					},
					dataLabels: {
						enabled: true,
						position: "top",
						offsetY: -40,
						style: {
							colors: ["#7E8299"],
							fontWeight: "normal",
							fontSize: "20px",
						},
						formatter: (value) => {
							return value + "$";
						},
					},
					plotOptions: {
						bar: {
							dataLabels: {
								position: "top",
							},
						},
					},
					markers: {
						size: 5,
					},
					noData: {
						text: "No data available for selected range",
						align: "center",
						verticalAlign: "middle",
						offsetX: 0,
						offsetY: 0,
						style: {
							color: "black",
							fontSize: "14px",
							fontFamily: undefined,
						},
					},
					legend: {
						show: true,
						showForSingleSeries: false,
						showForNullSeries: true,
						showForZeroSeries: true,
						position: "bottom",
						horizontalAlign: "center",
						floating: false,
						fontSize: "14px",
						fontFamily: "Helvetica, Arial",
						fontWeight: 400,
						formatter: undefined,
						inverseOrder: false,
						width: undefined,
						height: undefined,
						tooltipHoverFormatter: undefined,
						customLegendItems: [],
						offsetX: 0,
						offsetY: 0,
						labels: {
							colors: undefined,
							useSeriesColors: false,
						},
						markers: {
							width: 12,
							height: 12,
							strokeWidth: 0,
							strokeColor: "#fff",
							fillColors: undefined,
							radius: 12,
							customHTML: undefined,
							onClick: undefined,
							offsetX: 0,
							offsetY: 0,
						},
						itemMargin: {
							horizontal: 5,
							vertical: 0,
						},
						onItemClick: {
							toggleDataSeries: true,
						},
						onItemHover: {
							highlightDataSeries: true,
						},
					},
					// xaxis: {
					//   type: "category",
					//   categories: this.categories,
					// },
				},
				openTimeChartOptions : null,
			};
		},
		methods: {
			providerChanged() {
				this.loadData();
			},
			getOpenTimeColor(openTime) {
				return openTime === null ? "#7E8299" : openTime < 10 ? "#50cd25": openTime < 20 ? "#FF9100" : "#FF0000" ;

			},
			getPreviousMonday() {
				var prevMonday = new Date();
				prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
				return prevMonday.toISOString().split("T")[0];
			},
			getNextSunday() {
				var nextSunday = new Date();
				nextSunday.setDate(nextSunday.getDate() + ((7 - nextSunday.getDay()) % 7));
				return nextSunday.toISOString().split("T")[0];
			},
			async getProviders() {
				let res = await ApiService.query("Provider/Details?activeOnly=true");
				this.providerList.push({ id: "0", name: "All" });
				

				if (res) {
					res.data.forEach((element) => {
						
						if (element.isHygienist && element.excludeFromAnalysis == false) {
							this.providerList.push({ id: element.provider_id, name: element.first_name + " " + element.last_name });
						}
					});
				}

				if (this.providerList.length === 1) {
					res = await ApiService.get("Provider");
					if (res) {
						res.data.forEach((element) => {
							if (element.isHygienist && element.excludeFromAnalysis == false) {
								this.allProvidersList.push({ id: element.provider_id, name: element.first_name + " " + element.last_name });
							}
						});
					}					
				}				
			},
			async loadData() {

				let startDate = this.getPreviousMonday();
				let endDate = this.getNextSunday();

				let providerIdsToSend = null;
				if (this.selectedProvider === "0") {
					if (this.providerList.length > 1){ //see note on hack on allProvidersList
						providerIdsToSend = this.providerList.filter(p => p.id !== "0").map(p => ({id: p.id}));
					} else {
						//see note on hack on allProvidersList
						providerIdsToSend = this.allProvidersList.map(p => ({id: p.id}));
					}					
					if (this.$store.getters.currentUser.currentClinic.isAdmin || this.providerList.length === 1) {
						this.providersReflectedDescription = "All providers";
					} else {
						this.providersReflectedDescription = "providers " + this.providerList.filter(p => p.id !== "0").map(p => `\'${p.id}\'`).join(", ");
					}
				} else {
					providerIdsToSend = this.providerList.filter(p => p.id === this.selectedProvider).map(p => ({id: p.id}));
					this.providersReflectedDescription ="providers " + this.providerList.filter(p => p.id === this.selectedProvider).map(p => `\'${p.id}\'`).join(", ");
				}

				let providerIdsString = JSON.stringify(providerIdsToSend);
				let res = await ApiService.get("Dashboard/GetDashboard?startDate=" + startDate + "&endDate=" + endDate + "&providerIds=" + providerIdsString);
				if (res.data) {
					await this.loadSeries(res.data);
				}
				
				const today = DateHelper.getDatePortion(new Date());
				const tomorrow = DateHelper.getDatePortion(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000));
				const params = {
					startDate: startDate,
					endDate: endDate,
					compare: false,
					providerIds: providerIdsToSend.map(p => p.id).join(","),
				};				
				res = await ApiService.query("Report/OpenTimeTotals", { params });
				let data = res.data;
				data.forEach(d => d.dateObj = DateHelper.getDatePortionFromString(d.date));				
				DateHelper.sortArrayByProperty(data, "dateObj", "asc");
				console.log("opentimeraw", data);
				let openTimeData = data.find(d => d.date.split("T")[0] === today);
				this.todaysOpenTime  = openTimeData?.openTime ?? null;
				this.todaysOpenTimeHours  = openTimeData == null ? null : ((openTimeData.totalMinutesClocked - openTimeData.totalMinutesWorked) / 60).toFixed(1);
								
				let openTimeChartOptions = JSON.parse(JSON.stringify(this.chartOptions))
				openTimeChartOptions.dataLabels.offsetY = -65;
				openTimeChartOptions.dataLabels.formatter = (value, opts) => {
					const hours = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].hours;
					return value === null ? "N/A" : [value + "%", `${hours} hrs.`];
				}
				openTimeChartOptions.yaxis.max = 100;
				this.openTimeSeries = [{name : "open time", data: data.map(d => ({ y: d.openTime, x: this.getDateLabel(d.date), offsetY: 5,
					fillColor: this.getOpenTimeColor(d.openTime), hours : ((d.totalMinutesClocked - d.totalMinutesWorked) / 60).toFixed(1) }) )}]		
				this.openTimeChartOptions = openTimeChartOptions;

				console.log("openTimeChartOptions", this.openTimeChartOptions);
				console.log("chartOptions", this.chartOptions);
				console.log("opentime", this.openTimeSeries);
				console.log("series", this.series);
				//this.openTimeChartOptions = this.chartOptions;
				 
			},
			getDateLabel(date) {
				let label = "";
				switch (new Date(date).getDay()) {
						case 0:							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Sun");
							break;
						case 1:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Mon");
							break;
						case 2:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Tue");
							break;
						case 3:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Wed");
							break;
						case 4:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Thu");
							break;
						case 5:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Fri");
							break;
						case 6:
							
							new Date(date).getDay() == new Date().getDay() ? (label = "Today") : (label = "Sat");
							break;							
						default:
							break;
					}
					return label;
			},
			async loadSeries(data) {
				this.calculatedSeries = [];
				this.series = [];
				let total = 0;
				let label = "";
				let color = "";

				data.forEach((element) => {
					total = 0;
					label = "";
					color = "";

					if (new Date(element.date).getDay() == new Date().getDay()) {
						this.todayforcasted = element.averageHourlyProduction;
						this.numberOfAppts = element.appointmentsNeedingImprovement;
						this.numberOfPerfectAppts = element.perfectAppointments;
					}
					this.todayforcasted = Math.round(this.todayforcasted);

					if (new Date(element.date).getDay() == new Date().getDay() - 1) {
						this.oldTodayforcasted = element.averageHourlyProduction;
					}

					total = element.averageHourlyProduction ?? 0;
					label = this.getDateLabel(element.date)

					if (!this.checkIfExists(label, total)) {
						this.calculatedSeries.push({ y: total, x: label, fillColor: color });
					}
				});

				this.calculatedSeries.forEach((element) => {
					if (element.y > this.clinicGoal) {
						color = "#50cd89";
					} else {
						color = "#FF0000";
					}

					element.fillColor = color;
				});

				this.series.push({ name: "per hour", data: this.calculatedSeries });				

				this.chartOptions = {
					...this.chartOptions,
					...{
						yaxis: {
							max: Math.max.apply(
								Math,
								this.calculatedSeries.map(function(o) {
									return Math.ceil(o.y / 100) * 100;
								})
							),
						},
					},
				};
				console.log("per hour chartoptions", this.chartOptions);
				
			},
			checkIfExists(label, total) {
				let result = false;
				this.calculatedSeries.forEach((element) => {
					if (element.x == label) {
						element.y += total;
						result = true;
					}
				});

				return result;
			},
			calculatePercentage(oldIncome, newIncome) {
				if (oldIncome > newIncome && oldIncome > 0) {
					this.calculatedPercentage = (((oldIncome - newIncome) / oldIncome) * 100).toFixed(2);
				}

				if (newIncome > oldIncome && oldIncome > 0) {
					this.calculatedPercentage = (((newIncome - oldIncome) / oldIncome) * 100).toFixed(2);
				}

				if (oldIncome == 0) {
					this.calculatedPercentage = 100;
				}
			},
		},
		async mounted() {
			const store = useStore();
			const clinicInfo = store.getters.currentClinicInfo;
			const currentUser = store.getters.currentUser;
			if (clinicInfo) {
				this.clinicGoal = clinicInfo.goalHourlyProduction;
			}

			if (currentUser) {
				this.userFirstName = currentUser.firstName;
			}

			setCurrentPageTitle("Dashboard");

			

			await this.getProviders();
			await this.loadData();
		},
	});
</script>

<style lang="scss" scoped>
	.redclass {
		background-color: red;
		border-radius: 0.475rem;
	}

	.greenclass {
		background-color: #50cd89;
		border-radius: 0.475rem;
	}

	.customSvg {
		text-align: center;
		padding-top: 5px;		
	}

	.forcasted {
		font-size: 40px;
		color: white;
		margin: 0px;
		display: inline;
	}

	.forcastedText {
		color: white;
	}

	.perfectAppts {
		font-size: 40px;
		color: #50cd25;
		margin: 0px;
	}

	.opentime {
		font-size: 40px;		
		margin: 0px;
	}

	.needImprovments {
		font-size: 40px;
		color: #ff9100;
		margin: 0px;
	}

	.redBadge {
		font-size: 18px;
		color: red;
		background-color: white;
		padding: 5px;
		vertical-align: super;
	}

	.greenBadge {
		font-size: 18px;
		color: #50cd89;
		background-color: white;
		padding: 5px;
		vertical-align: super;
	}
</style>

<template>
	<div class="loader" v-if="loading"></div>
	<div class="card p-5">
		<div class="row">
			<div class="col-12 d-flex justify-content-between" style="padding-bottom: 5px;">
				<div class="col-4">
					<input id="inputdate" type="date" class="btn btn-secondary btn-sm" @click="showPicker"
						@change="onMiniCalendarDateSet" title="change calendar date" style="padding: 10px 12px 10px 12px;margin: 0.25rem 0.125rem" />

					<!-- <button v-if="this.$store.getters.currentUser.currentClinic.isAdmin" id="analyzeCalendar"
						class="btn btn-secondary btn-sm" @click="analyzeCalendar"
						title="Beta feature: Analyze appointments for optimal length" style="margin: 0.25rem 0.125rem">
						<img src="/media/magnifying_calendar_small.png" style="width:30px;height:30px" />
					</button> -->
					<button id="refreshCalendar" :disabled="preventRefresh"
						class="btn btn-secondary btn-sm" @click="refreshCalendar"
						title="Refresh" style="margin: 0.25rem 0.125rem; padding: 9px;">
						<i class="fa fa-refresh" aria-hidden="true" style="font-size:17px; "></i>					
					</button>
				</div>
				<div class="col-4" style="text-align: -webkit-right;display: flex;flex-direction: row-reverse;">
					<select class="form-select mx-1 phone-show" style="width:auto" 
							v-model="filterProviderId" v-if="providerList.length" @change="providerFilterChanged">
							<option :value="0">All</option>
							<option v-for="(provider, index) in providerList" :key="index" :value="provider.provider_id">
							{{ provider.provider_id }} - {{ provider.first_name }} {{ provider.last_name }}</option>
						</select>		
						<select class="form-select mx-1 " style="width:auto"   @change="providerFilterChanged"
							v-model="filterProviderGroupId" v-if="providerGroups?.length > 1">						
							<option v-for="(providerGroup, index) in providerGroups" :key="index" :value="providerGroup.providerGroupId">
							{{ providerGroup.name }}</option>
						</select>
								
					
				</div>
			</div>
		</div>

		<FullCalendar :options="calendarOptions" :custom-buttons="calendarOptions.customButtons" ref="calendar" />
	</div>

	<!-- start modal -->
	<div class="modal fade" tabindex="-1" id="print_work_day_plan_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Print Work Day Plan
					</h5>
					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>

				<div class="modal-body" v-if="providerList && providerList.length > 0">
					<select class="form-select" v-model="selectedProviderId">
						<option :value="null">Select a Provider</option>
						<option v-for="provider in providerList" :key="provider.provider_id" :value="provider.provider_id">
							{{ provider.first_name }} {{ provider.last_name }} ({{ provider.provider_id }})
						</option>
					</select>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button class="btn btn-success" @click="printWorkDayPlan"
						:title="`${selectedProviderId ? 'Click to print' : 'Please select a provider first'}`"
						:disabled="selectedProviderId == null"><i class="bi bi-printer"></i></button>
				</div>
			</div>
		</div>
	</div>
	<!-- end modal -->
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar, { CalendarApi } from "@fullcalendar/vue3";
import Avatar from "@/components/Avatar.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { createApp, defineComponent, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as bootstrap from "bootstrap";
import ApiService from "@/core/services/ApiService";
import { format, differenceInYears, differenceInMinutes, intervalToDuration } from "date-fns";
import { useStore } from "vuex";
import Modal from "@/components/Modal.vue";
import { left } from "@popperjs/core";
import { boolean } from "yup/lib/locale";
import InlineSvg from "vue-inline-svg";
import jsPDF from "jspdf";
import parseISO from "date-fns/esm/fp/parseISO";
import autoTable from "jspdf-autotable";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes.ts";
import { LOCAL_STORAGE_CONST } from "@/Models/local-storage.model";
import { DateHelper } from "@/Utilities/DateHelper.ts";

const DueProcedureStatus = 
{
	DueAndDone: 1,
	DueAndNotDone: 2,
	DueButCouldNotBeDone: 3
}

const GoalDisplayMode =
{
	HardCore: 1, //red if you miss goal wage
	Easy: 2, //red only if you don't use RNA
	EasyExceptForAdmin: 3
}

Date.prototype.addMinutes = function (m) {
	this.setTime(this.getTime() + m * 60 * 1000);
	return this;
};

export default defineComponent({
	name: "CalendarController",
	components: { FullCalendar, Avatar, Modal, InlineSvg },
	data() {
		return {
			preventRefresh: false, // to debounce the refresh button
			loading: false,
			toast: useToast(),
			printWorkDayPlanModal: null,
			takeBackToDefaultBusy: false,			
			showSelectProvider: false,			
			nonProviderList: [],
			middleDate: null, //this date is used to compare between the days if the day skips middleDate -60 or + 30 then the day becomes the middle date and populateTasks gets called with the new middleDate
			loader: false, //used to control the showing and hiding of the loader
			dailyEarnings: 0,
			appointmentsRes: null,
			optedServices: [], //services that were initially assigned from backend + services added from dentrix suggestions
			patientHistory: [],
			providerList: [],
			allProvidersToDisplay: [],
			providerGroups: [],
			resourceList: null,
			oldestDateLoaded: null,
			newestDateLoaded: null,
			isToday: false,
			week: [],
			today: [],
			selectedProviderId: null,
			filterProviderId: 0, //all
			filterProviderGroupId: 0, //all
			checkEventObject: null, //contains the task clicked information used to fill the modal with initial opted tasks
			themeSystem: "bootstrap5",
			calendarOptions: {
				//callendar options in extendedProps we need to add ishtml to yes and add a tasksarray tasks array will show as list in callendar and as a list in a table in modal under opted services
				plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin, resourceTimeGridPlugin, momentTimezonePlugin],
				headerToolbar: {
					left: "today, prev,next,share,printDay",
					center: "title",
					right: "",
				},
				customButtons: {
					printDay: {
						icon: "bi bi-printer",
						hint: "Print work day plan",
						click: async () => {
							if (this.instanceInfo.providers && this.instanceInfo.providers.length > 1 || this.instanceInfo.providers.length === 0) {
								this.printWorkDayPlanModal.show();
							} else {
								// this.printWorkDayPlanModal.show();
								await this.printWorkDayPlan();
							}
						},

					},
					share: {
						icon: "bi bi-clipboard",
						hint: "Share calendar day",
						click: async () => {
							this.copyCalendarDayLink();
						},
					},
					prev: {
						// this overrides the prev button
						text: "Previous",
						click: async () => {
							this.isToday = false;
							this.week = [];
							this.today = [];

							let calendarApi = this.$refs.calendar.getApi();
							let targetDate = new Date(calendarApi.getDate());
							targetDate.setDate(targetDate.getDate() - 1);
							await this.changeCalendarDate(targetDate);

							// var result = this.checkIfOutOfRange(targetDate);
							// if (result) {
							// 	// console.log("middle past")
							// 	await this.populateTasks(targetDate, this.selectedProviderId);
							// 	calendarApi.prev();
							// } else {
							// 	calendarApi.prev();
							// }

							// this.adjustTimeSlot(this.addDays(calendarApi.currentData.dateProfile.activeRange.start, 1), calendarApi.currentData.dateProfile.activeRange.end, calendarApi.currentData.currentViewType);
						},
					},
					next: {
						// this overrides the next button
						text: "Next",
						click: async () => {
							this.isToday = false;
							this.week = [];
							this.today = [];

							let calendarApi = this.$refs.calendar.getApi();
							let targetDate = new Date(calendarApi.getDate());
							targetDate.setDate(targetDate.getDate() + 1);
							await this.changeCalendarDate(targetDate);
						},
					},
					today: {
						// this overrides the next button
						text: "Today",
						click: async () => {
							this.week = [];
							this.today = [];

							let targetDate = new Date();
							await this.changeCalendarDate(targetDate);
						},
					},
					timeGridWeek: {
						// this overrides the next button
						text: "Week",
						click: async () => {
							this.week = [];
							this.today = [];
							this.showSelectProvider = true;

							let targetDate = new Date();
							let calendarApi = this.$refs.calendar.getApi();

							if (this.providerList.length > 0) {
								this.selectedProviderId = this.providerList[0].provider_id;
								await this.populateTasks(targetDate, this.selectedProviderId);

								calendarApi.changeView("timeGridWeek");
							}
							this.adjustTimeSlot(this.addDays(calendarApi.currentData.dateProfile.activeRange.start, 1), calendarApi.currentData.dateProfile.activeRange.end, calendarApi.currentData.currentViewType);
						},
					},
					resourceTimeGridDay: {
						// this overrides the next button
						text: "Day",
						click: async () => {
							this.selectedProviderId = null;
							this.week = [];
							this.today = [];
							this.showSelectProvider = false;
							let calendarApi = this.$refs.calendar.getApi();
							calendarApi.changeView("resourceTimeGridDay");
							let targetDate = new Date();
							await this.changeCalendarDate(targetDate);
						},
					},
				},
				filterResourcesWithEvents: true,
				timeZone: "America/New_York",
				initialView: "resourceTimeGridDay",
				schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
				slotMinTime: "4:00:00",
				slotMaxTime: "19:00:00",
				nowIndicator: true,
				allDaySlot: false,
				height: "calc(100vh - 210px)", //makes the calendar with bigger height to have more room for tiny events
				slotDuration: "00:10:00", //makes it each slot is 5 minutes to have more room for tiny events
				expandRows: true,
				resourceOrder: "calendarOrder",
				resources: [],
				dateClick: this.handleDateClick,
				eventClick: this.handleEventCick,
				datesSet: async (data) => {
					// this.adjustTimeSlot(data.start , this.minusDays(data.end , 1) ,data.view.type)
				},
				events: [
					// {
					//   resourceId: "a",
					//   title: "Dental cleaning",
					//   start: "2021-11-25T09:30:00",
					//   end: "2021-11-25T11:30:00",
					//   extendedProps: {
					//     isHTML: "YES",
					//     ammountBilled: 300,
					//     tasks: [
					//       {
					//         code: "-",
					//         service:
					//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.	",
					//         minutes: "3:00",
					//         charge: 0,
					//       },
					//       {
					//         code: "-",
					//         service: "Covid screening",
					//         minutes: "15:00",
					//         charge: 150,
					//       },
					//     ],
					//   },
					//   description: "Lecture<br/> bla bla bla bla bla bla bla bla",
					// },
				],
				eventContent: this.injectEventContent,
				// eventResize: this.saveEventResize, // this is the duration resize
				// eventDrop: this.saveEventDrop,//

			},
		};
	},
	computed: {},
	methods: {
		async refreshCalendar(){
			if (this.preventRefresh){
				return;
			}

			this.loading = true;
			let calendarApi = this.$refs.calendar.getApi();
			let currentlyDisplayedDay = DateHelper.getDatePortionAsDate(calendarApi.getDate());
			let currentlyDisplayedDayString = DateHelper.getDatePortion(calendarApi.getDate());

			try
			{
				// for now not doing this, but still want a way to refresh things
				// let results = await ApiService.postData("Clinic/GetAppointments",{ startDate: currentlyDisplayedDayString, endDate: currentlyDisplayedDayString },{});
				// if (results.status !== 200) {
          		// 	throw new Error("reposnse not 200");
        		// }
				// if (results.data  !== "done"){
				// 	throw new Error("agent not connected");
				// }
				await this.populateTasks(currentlyDisplayedDay, this.selectedProviderId);
				this.preventRefresh = true;
				setTimeout(() => { this.preventRefresh = false }, 10000);
			}
			catch(e){				
				this.toast.error(codes.error);
				throw e;
			}			
			this.loading = false;			
		},
		showPicker() {
			document.getElementById('inputdate').showPicker(); // ugly but works, otherwise the button click area is too small
		},
		downloadCustom(data, provId, name) {
			console.log(provId)
			const doc = new jsPDF("portrait", "mm", "A4");
			doc.addImage("/RNA 180__Logo_Original_Dark Backround_large.png", "PNG", 12, 12, 15, 12);
			doc.text(`Work Day Plan ${this.instanceInfo.providersIds[0] ? '(' + provId + ')' : ''}`, 30, 18);
			doc.text(this.instanceInfo.provId ?? "", 265, 16, "right");
			doc.setFontSize(10);
			console.log(" the name",name);
			doc.text(`${name}`,195, 18,"right");
			doc.setTextColor("#777");
			
			let pdfDay = this.$refs.calendar.getApi().getDate().toString().split(" ");
			pdfDay = `${pdfDay[0]} ${pdfDay[1]} ${pdfDay[2]} ${pdfDay[3]}`;
			doc.text(pdfDay, 30, 23);
			const prepareHeader = () => {
				if (this.$store.getters.currentClinicInfo.shouldTrackDoctor) {
					return ["Date", "Duration", "Patient", "Exam Dr", "X-Ray Rx Dr", "Code", "Service", "Time (minutes)"];
				}
				return ["Date", "Duration", "Patient", "Code", "Service", "Time (minutes)"];
			}
			let tableOptions = {
				styles: { fontSize: 7 },
				head: [prepareHeader()],
				body: [],
				startY: 28,
			}
			console.log(data)
			data.sort(function (a, b) {
				return new Date(a.appointmentDate) - new Date(b.appointmentDate);
			});
			console.log(data)
			const prepareAppointmentData = (appointment, procedure, isEmpty, isNoTreatmentConfigured) => {
				const appointmentDate = `${appointment.appointmentDate.split("T")[0]} ${appointment.appointmentDate.split("T")[1]}`;
				const durationInMinutes = `${appointment.durationInMinutes}`;
				const patientInitials = `${appointment.patientInitials}`;
				const examDoctor = `${appointment.examDoctor ? appointment.examDoctor.firstName + ' ' + appointment.examDoctor.lastName : 'N/A'}`;
				const orderingDoctor = `${appointment.orderingDoctor ? appointment.orderingDoctor.firstName + ' ' + appointment.orderingDoctor.lastName : 'N/A'}`;

				if (isNoTreatmentConfigured) {
					if (!isEmpty) {
						const noTreatmentMsg = 'No treatment configured for this appointment in RNA';
						if (this.$store.getters.currentClinicInfo.shouldTrackDoctor) {
							return [appointmentDate, appointmentDate, patientInitials, examDoctor, orderingDoctor, noTreatmentMsg, ``, ``];
						} else {
							return [appointmentDate, appointmentDate, patientInitials, noTreatmentMsg, ``, ``];
						}
					} else {
						if (this.$store.getters.currentClinicInfo.shouldTrackDoctor) {
							return [``, ``, ``, ``, ``, ``, ``, ``];
						} else {
							return [``, ``, ``, ``, ``, ``];
						}
					}

				} else {
					if (isEmpty) {
						if (this.$store.getters.currentClinicInfo.shouldTrackDoctor) {
							return [``, ``, ``, ``, ``, `${procedure.adaCode || ''}`, `${procedure.procedureName || ''}`, `${procedure.minutes || ''}`];
						}
						return [``, ``, ``, `${procedure.adaCode || ''}`, `${procedure.procedureName || ''}`, `${procedure.minutes || ''}`];
					} else {
						const adaCode = `${procedure.adaCode || ''}`;
						const procedureName = `${procedure.procedureName || ''}`;
						const minutes = `${procedure.minutes || ''}`
						if (this.$store.getters.currentClinicInfo.shouldTrackDoctor) {
							return [appointmentDate, durationInMinutes, patientInitials, examDoctor, orderingDoctor, adaCode, procedureName, minutes];
						}
						return [appointmentDate, durationInMinutes, patientInitials, adaCode, procedureName, minutes];
					}
				}
			}
			for (let i = 0; i < data.length; i++) {
				let appointment = data[i];
				for (let j = 0; j < appointment.procedures.length; j++) {
					let procedure = appointment.procedures[j];
					if (j == 0) {
						tableOptions.body.push(prepareAppointmentData(appointment, procedure, false, false));
					} else {
						tableOptions.body.push(prepareAppointmentData(appointment, procedure, true, false));
					}
				}
				// this is used for when an appointment doesn't have any procedures in it, we show that appointment but, we show that nothing is configured with RNA.
				//if this line is removed appointments with no procedure will not show in the PDF
				if (appointment.procedures.length === 0) {
					tableOptions.body.push(prepareAppointmentData(appointment, null, false, true));
				}
				tableOptions.body.push(prepareAppointmentData(appointment, null, true, true));
			}
			// tableOptions.body.push([])
			autoTable(doc, tableOptions);
			if (data.length == 0) {
				doc.setFont('sans-serif', "bold");
				doc.setTextColor("#B22222");
				doc.setFontSize(14);
				doc.text("No Records Found", 15, 50);
			}

			var pageCount=doc.internal.getNumberOfPages();
			for(var i=0;i<pageCount;i++)
			{
				doc.setPage(i);

				let pageCurrent= doc.internal.getCurrentPageInfo().pageNumber;

				doc.text("Page "+pageCurrent+"/"+pageCount,180,10)
				console.log("Page "+pageCurrent+"/"+pageCount)
			}
			let urlDay = this.$refs.calendar.getApi().getDate().toString();
			const dateelement=new Date(urlDay);

			const year = dateelement.getFullYear(); // Get the year (e.g., 2022)
			const month = dateelement.toLocaleString('default', { month: 'short' }); // Get the month as a full name (e.g., May)
			const day = dateelement.getDate(); // Get the day (e.g., 5)

// Format the date as "YYYY-month-day"
			const formattedDate = `${year}-${month.toLowerCase()}-${day}`;
			doc.save(`DayPlan_${provId}_${formattedDate}.pdf`);
		},
		downloadCustomPDF(data, provId) {
			const doc = new jsPDF("portrait", "mm", "A4");
			doc.addImage("/RNA180Logo_Transparent BG.png", "PNG", 12, 8, 15, 15);
			doc.text(`Work Day Plan ${this.instanceInfo.providersIds[0] ? '(' + provId + ')' : ''}`, 30, 18);
			doc.text(this.clinicName ?? "", 265, 18, "right");
			doc.setTextColor("#777");
			doc.setFontSize(10);
			let pdfDay = this.$refs.calendar.getApi().getDate().toString().split(" ");
			pdfDay = `${pdfDay[0]} ${pdfDay[1]} ${pdfDay[2]} ${pdfDay[3]}`;
			doc.text(pdfDay, 30, 23);
			let tableOptions;
			tableOptions = {
				styles: { fontSize: 7 },
				head: [["Date", "Duration", "Patient", "Code", "Service", "Time (minutes)"]],
				body: [],
				startY: 28,
			}
			data.sort(function (a, b) {
				return new Date(a.appointmentDate) - new Date(b.appointmentDate);
			});
			console.log(data)
			for (let i = 0; i < data.length; i++) {
				let appointment = data[i];
				for (let j = 0; j < appointment.procedures.length; j++) {
					let procedure = appointment.procedures[j];
					if (i % 2 === 0) {//even

					} else {//odd

					}

				}
				// this is used for when an appointment doesn't have any procedures in it, we show that appointment but, we show that nothing is configured with RNA.
				//if this line is removed appointments with no procedure will not show in the PDF
				if (appointment.procedures.length === 0) {
					tableOptions.body.push(
						[
							`${appointment.appointmentDate.split("T")[0]} ${appointment.appointmentDate.split("T")[1]}`,
							`${appointment.durationInMinutes}`,
							`${appointment.patientInitials}`,
							``,
							`No treatment configured for this appointment in RNA`,
							``,
						]
					)
				}
				tableOptions.body.push(
					[
						``,
						``,
						``,
						``,
						``,
						``,
					]
				)

			}
			// tableOptions.body.push([])
			autoTable(doc, tableOptions);
			if (data.length == 0) {
				doc.setFont('sans-serif', "bold");
				doc.setTextColor("#B22222");
				doc.setFontSize(14);
				doc.text("No Records Found", 15, 50);
			}
			doc.save(`Work Day Plan ${this.instanceInfo.providersIds[0] ? "(" + this.instanceInfo.providersIds[0] + ")" : ""}.pdf`);
		},
		async printWorkDayPlan() {
			try {
				let calendarApi = this.$refs.calendar.getApi();
				console.log("before",this.selectedProviderId, this.providerList)
				let currentProvider = this.selectedProviderId || this.instanceInfo.providersIds[0];
				let providerInstance=this.providerList.find(function(e) {
					return e.provider_id==currentProvider;
				});
				let currentDate = new Date(calendarApi.getDate()).toISOString();
				let params = {
					date: currentDate.split("T")[0],
					providerID: currentProvider
				}
				this.loading = true;
				let res = await ApiService.query("Report/WorkDayPlan", { params });
				this.loading = false;
				if (res.status === 200) {
					console.log(res)
					this.downloadCustom(res.data, currentProvider,providerInstance.first_name)
					this.printWorkDayPlanModal.hide();
				} else {
					console.error("workdayPlan")
					this.toast.error(codes.error)
				}
			} catch (e) {
				console.log(e)
				this.toast.error(codes.error)
			}
		},
		copyCalendarDayLink() {
			let calendarApi = this.$refs.calendar.getApi();
			let baseLink = window.location.href;
			let current_clinic_id = this.clinicInfo.clinicGuid;
			baseLink = baseLink.split("?")[0];
			const dateString = calendarApi.getDate().toISOString().split('T')[0];
			if (this.providerList.length && this.showSelectProvider) {
				// weekly
				navigator.clipboard.writeText(
					`${baseLink}?date=${dateString}&p_id=${this.selectedProviderId
					}&weekly=true&c=${current_clinic_id}`
				);
			} else {
				// daily
				navigator.clipboard.writeText(
					`${baseLink}?date=${dateString}&c=${current_clinic_id}`
				);
			}
			this.$root.showMessage("copied to clipboard!");
		},
		saveEventDrop(info) {
			console.log("droped", info);
		},
		saveEventResize(info) {
			console.log("resized", info);
		},
		injectEventContent(arg) {

			const divEl = document.createElement("div");
			const planned = arg.event.extendedProps.apptObject.rnaTotalPlanned === 0 || !arg.event.extendedProps.apptObject.rnaTotalPlanned ? arg.event.extendedProps.apptObject.totalPlanned : arg.event.extendedProps.apptObject.rnaTotalPlanned;
			const completed = arg.event.extendedProps.apptObject.totalCompleted;
			const borderColor = arg.event.extendedProps.borderColor;

			let dollarValueToUse = 0;
			if (new Date(arg.event.extendedProps.apptObject.endDate).addMinutes(7) < this.changeNowTimezone()) {
				dollarValueToUse = completed;
			} else {
				dollarValueToUse = planned;
			}

			// console.log(arg.event.extendedProps.apptObject);

			const calculatedRate = Math.round((dollarValueToUse / arg.event.extendedProps.apptObject.durationInMinutes) * 60);

			let color = { background: "", color: "" };
			if (borderColor === "#ff00001a") {
				color.background = "#ff00001a";
				color.color = "red";
			} else if (borderColor === "#ffa5000d") {
				color.background = "#fff8dd";
				color.color = "orange";
			} else {
				color.background = "#009ef70d"; // blue: #009ef70d
				color.color = "#009ef7"; // font color: blue: #3788d8
			}

			const isHygieneAppointment = this.providerList.find((x) => x.provider_id == arg.event.extendedProps.apptObject.providerId) ? true : false;
			const isUserAHygenist = this.instanceInfo.providersIds && this.instanceInfo.providersIds.length > 0 ? true : false;
			const isUsersAppointment = this.instanceInfo.providersIds.find((x) => x == arg.event.extendedProps.apptObject.providerId) ? true : false;
			let isAllowedToSee = false;

			if (isHygieneAppointment) {
				if (!isUserAHygenist) {
					isAllowedToSee = true;
				}
				if (isUserAHygenist && isUsersAppointment) {
					isAllowedToSee = true;
				}
			}

			if (this.$store.getters.currentUser.preferences){
				let prefs = JSON.parse(this.$store.getters.currentUser.preferences)
				if (prefs.performanceDisplay === "none"){
					isAllowedToSee = false;
				}
			}

			let analysisHtml = "";
			let almostDueHtml = "";
			let missedHtml = "";
			let nextApptHtml = "";
			const analysisResult = arg.event.extendedProps.analysisResult
			const durationInMinutes = arg.event.extendedProps.apptObject.durationInMinutes;
			if (analysisResult) {
				if (analysisResult.needsReview === false) {
					analysisHtml = `<img src="/media/alarm_check_small.png" width=25px height=25px title="${analysisResult.details}"></img>`
				} else if (analysisResult.needsReview === true && durationInMinutes < analysisResult.recommendedMinutes) {
					analysisHtml = `<img src="/media/alarm_plus_small.png" width=25px height=25px title="${analysisResult.details}"></img>`
				} else if (analysisResult.needsReview === true && durationInMinutes > analysisResult.recommendedMinutes) {
					analysisHtml = `<img src="/media/alarm_minus_small.png" width=25px height=25px title="${analysisResult.details}"></img>`
				}

				if (analysisResult.almostDueProcedures && analysisResult.almostDueProcedures.length > 0) {
					let title = ""
					for (let p of analysisResult.almostDueProcedures) {
						const dateString = this.dateFormat(DateHelper.getDatePortionFromString(p.dueDate), 'yyyy-MMM-dd')
						title += `${p.procedureName} is due in ${p.inDays} days on ${dateString}\n`;
					}
					almostDueHtml = `<i class="fa-regular fa-lightbulb" style="color: black !important;font-size: 18px;vertical-align: middle;" title="${title}"></i>`
				}

				if (analysisResult.dueProcedures && analysisResult.dueProcedures.filter(d => d.dueProcedureStatus === DueProcedureStatus.DueAndNotDone).length > 0) {
					let title = ""
					for (let p of analysisResult.dueProcedures.filter(d => d.dueProcedureStatus === DueProcedureStatus.DueAndNotDone)) {
						title += `${p.procedureName} was due but not completed\n`;
					}
					missedHtml = `<i class="fa-solid fa-tooth" style="color: black !important;font-size: 18px;vertical-align: middle;" title="${title}"></i>`
				}

				if (analysisResult.nextAppointmentSummary) {
					nextApptHtml = `<i class="fa-regular fa-calendar next-calendar" style="cursor: pointer;color: black !important;font-size: 18px;vertical-align: middle;"  data-appointmentId="${analysisResult.nextAppointmentSummary.nextAppointmentId}" title="Next appointment is booked\nDate: ${DateHelper.getDatePortion(DateHelper.getDatePortionFromString(analysisResult.nextAppointmentSummary.nextAppointmentDate))}\nLength: ${analysisResult.nextAppointmentSummary.nextAppointmentLength} min."></i>`
				}
			}

			const dolarValHtml = `<div class='row w-100 mb-0 text-end mr-minus-1' style='color: ${color.color};'>
	                                      <b>$${dollarValueToUse}</b>
	                                    </div>`;
			const dolarPerHourHtml = `<div class='col text-end mt-2' style='padding-right: 0px !important;white-space: nowrap;'>
					${missedHtml}
					${almostDueHtml}
					${analysisHtml}
					${nextApptHtml}` + (isAllowedToSee ? `
					<span class='p-1' style='background: ${color.color}; border-radius: 5px;'>${calculatedRate}$/hr</span>`: "") +
					`</div>`;


			let isOdspHtml = "";
			if (arg.event.extendedProps.isOdsp === true) {
				isOdspHtml = "<img src='/odsp.png' title='ODSP' width='15' style='padding-bottom:3px;'/>";
			}

			let insuranceIcon = "";
			if (arg.event.extendedProps.insuranceIconName) {
				insuranceIcon = `<img src='/media/Insurance/${arg.event.extendedProps.insuranceIconName}' title='${arg.event.extendedProps.insuranceIconName}' width='15' style='padding-bottom:3px;'/>`;
			}			

			let isBirthdayHtml = "";
			if (arg.event.extendedProps.isBirthday === true) {
				isBirthdayHtml = "<i class='fa-solid fa-cake-candles fa-beat' title='Happy Birthday!'></i>";
			}

			let commentsHtml = ""
			if (arg.event.extendedProps.comments) {
				commentsHtml = `<i style="color: ${arg.event.extendedProps.commentState === 1 ? 'orange' : 'grey'} !important; font-size:large" class="fa-solid fa-note-sticky" title="${arg.event.extendedProps.comments}"></i>`
			}			

			const htmlTitle = `<div class='row d-flex justify-content-between w-100 mt-1' style='font-size:15px; margin-right: 0px !important;'>
	                                      <div class='col' style='color: ${color.color}; margin-left: 0.25rem;' class='text-start'><b>${arg.event.title}</b> 
											${commentsHtml}
											${isOdspHtml}	
											${insuranceIcon}	
											${isBirthdayHtml}												
											<br/> 
											<em>${arg.event.extendedProps.apptObject.operatoryId || "opID"}</em></div>											
											${dolarPerHourHtml}
	                                    </div>
	                                    ${isAllowedToSee ? dolarValHtml : ""}
	                                    <p style='color: ${color.color}; margin-left: 5px;'>
	                                      ${arg.event.extendedProps.appointmentDescription}
	                                    </p>`;
			if (arg.event.extendedProps.isHTML) {
				divEl.innerHTML = htmlTitle;
			} else {
				divEl.innerHTML = arg.event.title;
			}
			divEl.style.overflowY = "hidden";

			divEl.id = arg.event.extendedProps.apptObject.appointmentId;
			divEl.classList = ["cornered-border"];
			divEl.style.height = "-webkit-fill-available";
			const arrayOfDomNodes = [divEl];
			return { domNodes: arrayOfDomNodes };
		},
		adjustTimeSlot(start, end, type) {
			if (this.appointmentsRes) {
				this.appointmentsRes.forEach((dayObj) => {
					if (new Date(dayObj.date).toDateString() == start.toDateString()) {
						this.today.push(dayObj);
					}

					if (new Date(dayObj.date) >= start && new Date(dayObj.date) <= end) {
						this.week.push(dayObj);
					}
				});

				let Obj = [];
				let AllAppointments = [];
				let minTime = new Date().toISOString();
				let maxTime = new Date().toISOString();

				if (this.today.length > 0 && type == "resourceTimeGridDay") {
					this.dailyEarnings = this.today[0].totalCompleted;
					Obj = this.today;
				} else {
					this.dailyEarnings = 0;
				}

				if (this.week.length > 0 && type == "timeGridWeek") {
					this.dailyEarnings = 0;
					Obj = this.week;
				}

				if (Obj && Obj.length > 0) {
					Obj.forEach((element) => {
						element.providers.forEach((provider) => {
							provider.appointments.forEach((appt) => {
								AllAppointments.push(appt);
							});
						});
					});
				}

				if (AllAppointments && AllAppointments.length > 0) {
					minTime = AllAppointments[0].startDate;
					maxTime = AllAppointments[0].endDate;

					for (let i = 0; i < AllAppointments.length; i++) {
						if (AllAppointments[i].endDate.toString().split("T")[1] > maxTime.toString().split("T")[1]) maxTime = AllAppointments[i].endDate;
						else if (AllAppointments[i].startDate.toString().split("T")[1] < minTime.toString().split("T")[1]) minTime = AllAppointments[i].startDate;
					}
				}

				if (minTime == maxTime) {
					this.calendarOptions.slotMinTime = "7:00:00";
					this.calendarOptions.slotMaxTime = "19:00:00";
				} else {
					this.calendarOptions.slotMinTime = minTime.toString().split("T")[1];
					this.calendarOptions.slotMaxTime = maxTime.toString().split("T")[1];
				}
			}
		},
		convertDifferenceInMinutesToHumanReadable(diff) {
			const hours = diff.hours < 10 ? (diff.hours === 0 ? null : "0" + diff.hours) : diff.hours;
			const minutes = diff.minutes < 10 ? (diff.minutes === 0 ? "00" : "0" + diff.minutes) : diff.minutes;
			const seconds = diff.seconds < 10 ? (diff.seconds === 0 ? "00" : "0" + diff.seconds) : diff.seconds;
			return `${hours ? hours + ":" : ""}${minutes ? minutes + ":" : ""}${seconds ? seconds : ""}`;
		},
		checkIfOutOfRange(targetDate) {
			if (!this.oldestDateLoaded || !this.newestDateLoaded) {
				return true;
			}

			if (targetDate < this.oldestDateLoaded || targetDate > this.newestDateLoaded) {
				return true;
			} else {
				return false;
			}
		},
		async onMiniCalendarDateSet(e) {
			let dateToSet = new Date(e.target.value);
			dateToSet = new Date(dateToSet.getTime() + dateToSet.getTimezoneOffset() * 60000);
			this.changeCalendarDate(dateToSet);

		},
		async analyzeCalendar(e) {
			console.log("analyze me")
			let calendarApi = this.$refs.calendar.getApi();
			let currentlyDisplayedDay = DateHelper.getDatePortion(calendarApi.getDate());
			let analysisResult = await ApiService.query(`appointment/Analyze?startDate=${currentlyDisplayedDay}&endDate=${currentlyDisplayedDay}`);
			console.log(currentlyDisplayedDay)
			console.log(analysisResult)

			for (let event of calendarApi.getEvents()) {
				if (event._def.extendedProps.apptObject.startDate.split("T")[0].toString() === currentlyDisplayedDay.toString()) {
					//this means its today.

					//let's only do hygiene 
					let providerId = event._def.extendedProps.apptObject.providerId;
					if (this.providerList.filter(p => p.provider_id === providerId).length > 0) {
						//let's find the result
						let res = analysisResult.data.filter(a => a.appointmentId === event._def.extendedProps.apptObject.appointmentId)[0];
						console.log(event._def.extendedProps.apptObject.appointmentId);
						event.setExtendedProp("analysisResult", res)
					}


				}
				//

			}
			//calendarApi.render();
		},
		async changeCalendarDate(dateToSet) {
			let calendarApi = this.$refs.calendar.getApi();
			this.isToday = true;
			var result = this.checkIfOutOfRange(dateToSet);
			if (result) {
				await this.populateTasks(dateToSet, this.selectedProviderId);
			}
			calendarApi.gotoDate(dateToSet);
			//if(e && e.timeStamp){calendarApi.next();}
			this.$store.lastCalendarDate = dateToSet

			this.adjustTimeSlot(this.addDays(calendarApi.currentData.dateProfile.activeRange.start, 1), calendarApi.currentData.dateProfile.activeRange.end, calendarApi.currentData.currentViewType);
		},
		dateFormat(date, formatString) {
			return format(date, formatString);
		},
		dateSubtract(date1, date2) {
			const years = date1.getYear() - date2.getYear();
			const diff = date1 - date2;
			const diffInDays = Math.floor(diff / 1000 / 3600 / 24);
			const diffInMonths = Math.floor(diffInDays / 30);
			const remainder = diffInDays - diffInMonths * 30;
			return `${diffInMonths} mths, ${remainder} days`;
		},
		getTodaysEarnings(target) {
			if (!this.appointmentsRes) {
				return 0;
			}
			let sum = 0;
			this.appointmentsRes.forEach((dayObj) => {
				if (new Date(dayObj.date).toString() == target.toString()) {
					sum = dayObj.totalCompleted;
				}
			});
			this.dailyEarnings = sum;
		},
		addToResources(id, name) {
			this.calendarOptions.resources.push({ id: id, title: name });
		},
		addDays(date, days) {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		},
		minusDays(date, days) {
			var result = new Date(date);
			result.setDate(result.getDate() - days);
			return result;
		},
		changeNowTimezone() {
			let calendarApi = this.$refs.calendar.getApi();
			let selectedTimezone = calendarApi.currentData.dateEnv.timeZone;
			let date = new Date();

			let newTimeZoneDate = date.toLocaleString("en-US", { timeZone: selectedTimezone });

			return new Date(newTimeZoneDate);
		},
		changeTimezone(date) {
			let calendarApi = this.$refs.calendar.getApi();
			let selectedTimezone = calendarApi.currentData.dateEnv.timeZone;
			let newTimeZoneDate = date.toLocaleString("en-US", { timeZone: selectedTimezone });

			return new Date(newTimeZoneDate);
		},
		async populateTasks(calendarDate, providerId) {
			let firstDate = this.minusDays(calendarDate, 14)
			let lastDate = this.addDays(calendarDate, 14);


			const range = {
				startDate: firstDate.toISOString(),
				endDate: lastDate.toISOString(),
			};

			let params = {
					startDate: range.startDate.split("T")[0].toString(),
					endDate: range.endDate.split("T")[0].toString(),
					providerId: this.selectedProviderId
				};

			try {
				this.selectedProviderId = providerId;

				let res = [];
				this.loading = true;
				res = await ApiService.query("appointment", { params });
				this.loading = false;
				if (res.status !== 200) {
					this.toast.error(codes.error);
				}

				this.appointmentsRes = res.data;

				const targetDate = calendarDate.toISOString().split("T")[0];
				if (this.selectedProviderId == null) {
					this.getTodaysEarnings(targetDate);
				}
				this.calendarOptions.events = [];
				this.appointmentsRes.forEach((dayObj) => {
					if (dayObj.date == calendarDate && this.selectedProviderId == null) {
						this.dailyEarnings = dayObj.totalBilled;
					}

					// this.appointmentsRes.forEach((day) => {
					//   const DayDate = day.date.split("T")[0];
					//   day.providers.forEach((provider) => {
					//     if(this.selectedProviderId == null){
					//       this.calendarOptions.resources.push({
					//         id: `${provider.providerId}_${DayDate}`,
					//         title: `${provider.firstName} ${provider.lastName} (${
					//           provider.providerId
					//         }) $${
					//           new Date(DayDate) < new Date(today.toISOString().split("T")[0])
					//             ? provider.totalCompleted
					//             : provider.totalPlanned
					//         }`,
					//       });
					//     }else{
					//         this.calendarOptions.resources = [];
					//     }
					//   });
					// });

					dayObj.providers.forEach((prov) => {
						const appointments = prov.appointments;
						DateHelper.sortArrayByProperty(appointments, "startDate", "asc");
						const appointmentChain = appointments.map(a => a.appointmentId); 						
						for(let apptIndex = 0; apptIndex < appointments.length; apptIndex++){
							let appt = appointments[apptIndex];
							const DayDate = appt.startDate.split("T")[0];
							const planned = appt.rnaTotalPlannedpriceOfScaling * this.clinicInfo.goalPercentage;
							const completed = appt.totalCompleted;
							let dollarValueToUse = 0;
							let appointmentDescription = appt.reason;
							if (new Date(appt.endDate).addMinutes(7) < this.changeNowTimezone()) {
								dollarValueToUse = completed;
							} else {
								if ((appt.rnaTotalPlanned && appt.rnaTotalPlanned > 0) || (appt.totalPlanned && appt.totalPlanned > 0)) {
									dollarValueToUse = appt.rnaTotalPlanned === 0 || !appt.rnaTotalPlanned ? appt.totalPlanned : appt.rnaTotalPlanned;
									appointmentDescription = appt.rnaReason || appt.reason;
								} else {
									dollarValueToUse = planned;
								}
							}

							let className = "eventUnknown";
							let backgoundColor = "009ef70d";
							let borderColor = "009ef70d";
							// console.log(arg.event.extendedProps.apptObject);
							if ((!this.instanceInfo.providersIds.find((x) => x == appt.providerId) && this.instanceInfo.isProvider) || this.nonProviderList.find((x) => x.provider_id == appt.providerId) || appt.providerAppointmentGoal == null) {
								borderColor = "#009ef70d";
								backgoundColor = "#009ef70d";
								className = "eventUnknown";
							} else if (this.clinicInfo.goalDisplayMode == GoalDisplayMode.HardCore
								|| (this.$store.getters.currentUser.currentClinic.isAdmin && this.clinicInfo.goalDisplayMode == GoalDisplayMode.EasyExceptForAdmin)) {
								const calculatedRate = Math.round((dollarValueToUse / appt.durationInMinutes) * 60);
								if (appt.providerAppointmentGoal) {
									backgoundColor =
										dollarValueToUse > appt.providerAppointmentGoal
											? "#e8f7ee4d" //blue or green
											: new Date(appt.endDate).addMinutes(7) < this.changeNowTimezone()
												? "#ff00001a" //red
												: "#fff8dd"; //orange					

									borderColor = dollarValueToUse >= appt.providerAppointmentGoal ? "#e8f7ee4d" : new Date(appt.endDate).addMinutes(7) < this.changeNowTimezone() ? "#ff00001a" : "#ffa5000d";
									if (dollarValueToUse >= appt.providerAppointmentGoal) {
										className = "eventSuccess";
									} else if (new Date(appt.endDate).addMinutes(7) < this.changeNowTimezone()) {
										className = "eventFail";
									} else {
										className = "eventWarning";
									}
								}
							}
							else { //this is easy mode
								// green if appointment has a good plan
								// green if appointment has a poor plan (plan that doesn't hit the goal) but is in the past (can't really change it - so let's not stress anyone out)
								// green if appointment was executed well (regardless if it had a good plan or not - they did well, so let's make it green)
								// orange if appointment has a poor plan and is in the future (you still have a chance to change it)
								// red if appointment is in the past and had no plan at all - meaning RNA wasn't used to optimize this appointment 

								if (appt.rnaTotalPlanned >= appt.providerAppointmentGoal || dollarValueToUse >= appt.providerAppointmentGoal) {//means they have a great plan and that's what we will hold them to for now - or if it was executed well
									className = "eventSuccess";
									backgoundColor = "#e8f7ee4d"
									borderColor = "#e8f7ee4d"
								} else {
									if (new Date(appt.endDate).addMinutes(7) < this.changeNowTimezone()) {

										if (appt.rnaTotalPlanned > 0) {// at least you used RNA
											//once its too late, don't worry about it
											className = "eventSuccess";
											backgoundColor = "#e8f7ee4d";
											borderColor = "#e8f7ee4d";
										} else {
											className = "eventFail";
											backgoundColor = "#ff00001a";
											borderColor = "#ff00001a";
										}
									} else {
										className = "eventWarning";
										backgoundColor = "#ffa5000d";
										borderColor = "#ffa5000d"
									}
								}
							}

							let isBirthday = false;
							if (appt.birthDate){
								let birthDate = DateHelper.getDatePortionFromString(appt.birthDate);
								let appointmentDate = DateHelper.getDatePortionFromString(appt.startDate);
								isBirthday = birthDate.getMonth() === appointmentDate.getMonth() && birthDate.getDate() === appointmentDate.getDate()
							}
							//  divEl.style.borderLeft =
							//           divEl.style.borderTopLeftRadius = "5px";
							//           divEl.style.borderBottomLeftRadius = "5px";

							//           divEl.style.overflowY = "hidden";
							this.calendarOptions.events.push({
								// resourceId: this.selectedProviderId == null ? `${appt.providerId}_${DayDate}` : appt.providerId ,
								resourceId: `${appt.providerId}`,
								// editable: this.instanceInfo.isClinic || this.instanceInfo.providersIds.find(x=> x == appt.providerId) ? true:false,
								// eventResourceEditable: false,
								title: appt.patientInitials,
								start: appt.startDate,
								classNames: [className],
								end: appt.endDate,
								description: "Lecture",
								extendedProps: {
									isHTML: "YES",
									apptObject: appt,
									backgoundColor: backgoundColor,
									borderColor: borderColor,
									appointmentDescription: appointmentDescription,
									isOdsp: appt.isOdsp,									
									insuranceIconName: appt.insuranceIconName,									
									appointmentChain: appointmentChain,
									isBirthday: isBirthday,
									comments: appt.comments,
									commentState: appt.commentState,
									nextAppt: appt.nextAppointmentId ? { nextAppointmentId: appt.nextAppointmentId, nextAppointmentDate: appt.nextAppointmentDate } : null
								},
							});							
						}
					});
				});

				this.oldestDateLoaded = firstDate;
				this.newestDateLoaded = lastDate;

				//analyzeCalendar();

				console.log("analyze me")				
				params = {
					startDate: params.startDate,
					endDate: params.endDate
				};
				ApiService.query(`appointment/Analyze`, { params }).then((analysisResult) => {
					console.log(analysisResult)
					let calendarApi = this.$refs.calendar.getApi();
					for (let event of this.calendarOptions.events) {
						//let's only do hygiene 
						let providerId = event.extendedProps.apptObject.providerId;
						if (this.providerList.filter(p => p.provider_id === providerId).length > 0) {
							//let's find the result
							let res = analysisResult.data.filter(a => a.appointmentId === event.extendedProps.apptObject.appointmentId)[0];
							//console.log(event._def.extendedProps.apptObject.appointmentId);
							event["analysisResult"] = res;
						}
					}

					// for (let event of calendarApi.getEvents()) {
					// 	//let's only do hygiene 
					// 	let providerId = event._def.extendedProps.apptObject.providerId;
					// 	if (this.providerList.filter(p => p.provider_id === providerId).length > 0) {
					// 		//let's find the result
					// 		let res = analysisResult.data.filter(a => a.appointmentId === event._def.extendedProps.apptObject.appointmentId)[0];
					// 		//console.log(event._def.extendedProps.apptObject.appointmentId);
					// 		event.setExtendedProp("analysisResult", res)
					// 	}
					// }
				});


				//
			} catch (e) {				
				this.toast.error(codes.error);
				throw e;

			}
		},
		handleDateClick(params) {
			//could be used to add an event on click on calendar
			// console.log(params);
		},
		async handleEventCick(event) {
			console.log(event, "bla");
			// open modal that has opted and suggested services where you can or remove services at will
			// console.log(event)
			this.loader = true;
			this.$store.calendarObject = {
				oldestDateLoaded: this.oldestDateLoaded,
				newestDateLoaded: this.newestDateLoaded,
				calendar: this.calendarOptions,
				filterProviderId: this.filterProviderId,
				filterProviderGroupId: this.filterProviderGroupId,
				appointmentsRes: this.appointmentsRes,
			};

			this.$store.fc_scroll = document.querySelector("#kt_content > div > div > div > div > div > div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr > td > div").firstChild.scrollTop;
			this.$store.lastCalendarDate = event.event.start;
			const chainString = event.event._def.extendedProps.appointmentChain.join("-");
			const apptId = event.event._def.extendedProps.apptObject.appointmentId;			
			const nextApptId = event.event._def.extendedProps.analysisResult?.nextAppointmentSummary?.nextAppointmentId;
			if (event.jsEvent.srcElement.className.includes("next-calendar")) {
				this.$router.push(
				{ 	
					path: `/appointment/${nextApptId}`, 	query: { originDate: DateHelper.getDatePortion(event.event.start)}					
				});
			} else {
				this.$router.push(
				{ 	
					path: `/appointment/${apptId}`, 
					query: {					
						chain: chainString
					}
				});
			}

			
			// console.log(event.event._def.extendedProps.apptObject.appointmentId);
			

			//commented those out below because it was used in the older version when we used to fetch and put them in a modal now not used because were going to another page and fetching them there
			// const optedRes = await ApiService.get(`/Appointment/${apptId}`);
			// if (optedRes.status == 200) {
			// 	// console.log(optedRes.data);
			// }
			// this.checkEventObject = event;
			// //check if selected has the same as rec if yes add rec to selected with show = false unless its the one that is in selected,
			//
			// this.optedServices = optedRes.data || [];
			// for (let i = 0; i < this.optedServices.selectedRecommendations.length; i++) {
			// 	for (let j = 0; j < this.optedServices.recommendations.length; j++) {
			// 		if (this.optedServices.selectedRecommendations[i].procedureRuleTypeId === this.optedServices.recommendations[j].procedureRuleTypeId) {
			// 			if (this.optedServices.selectedRecommendations[i].procedureOptions && this.optedServices.selectedRecommendations[i].procedureOptions.length > 0) {
			// 				//procedure that has options we will add the options to the selected but with show = false;
			// 				for (let k = 0; k < this.optedServices.recommendations[j].procedureOptions.length; k++) {
			// 					if (this.optedServices.recommendations[j].procedureOptions[k].adaCode !== this.optedServices.selectedRecommendations[i].procedureOptions[0].adaCode) {
			// 						this.optedServices.recommendations[j].procedureOptions[k].show = false;
			// 						this.optedServices.selectedRecommendations[i].procedureOptions.push(this.optedServices.recommendations[j].procedureOptions[k]);
			// 					} else {
			// 						this.optedServices.selectedRecommendations[i].procedureOptions[0].show = true;
			// 					}
			// 				}
			// 				this.optedServices.recommendations.splice(j, 1);
			// 			} else {
			// 				//normal procedure without options remove it cz its already in selected
			// 				// console.log(this.optedServices.recommendations[j]);
			// 				this.optedServices.recommendations.splice(j, 1);
			// 			}
			// 		}
			// 	}
			// }
			// console.log("Modal object", this.optedServices);
			// this.patientHistory = optedRes.data.proceduresHistory || [];
			// this.loader = false;
			// this.openEventModal();
		},
		async getProviders() {
			try {
				this.loading = true;
				let res = await ApiService.get("Provider");
				this.loading = false;
				// console.log(res.data);
				if (res) {
					let tempProviderList = res.data;
					tempProviderList.forEach((element) => {
						if (element.excludeFromCalendar == false){
							this.allProvidersToDisplay.push(element);
						}
						if (element.isHygienist && element.excludeFromCalendar == false) {
							this.providerList.push(element);
						} else {
							this.nonProviderList.push(element);
						}
					});

					console.log("providerList", this.providerList);
				}
				if (res.status !== 200) {
					this.toast.error(codes.error)
				}
			} catch (e) {
				console.log(e)
				this.toast.error(codes.error)
			}
		},
		async takeBackToDefault_selectedProviders() {
			if (this.takeBackToDefaultBusy) {
				return;
			}
			this.takeBackToDefaultBusy = true;
			this.selectedProviderId = null;
			this.week = [];
			this.today = [];
			this.showSelectProvider = false;
			let calendarApi = this.$refs.calendar.getApi();
			await this.populateTasks(new Date(), this.selectedProviderId);
			calendarApi.changeView("resourceTimeGridDay");
			this.adjustTimeSlot(this.addDays(calendarApi.currentData.dateProfile.activeRange.start, 1), calendarApi.currentData.dateProfile.activeRange.end, calendarApi.currentData.currentViewType);
			this.takeBackToDefaultBusy = false;
		},

		async providerFilterChanged(e) {			
			await this.populateProviders(this.filterProviderGroupId, this.filterProviderId);
		},
		async populateProviders(providerGroupIdSelection, providerIdSelection){
			this.calendarOptions.resources = [];
			let filteredProviders = [];

			if (providerIdSelection !== 0){
				filteredProviders = this.allProvidersToDisplay.filter((provider) => provider.provider_id === providerIdSelection && !provider.excludeFromCalendar);
			} else if (providerGroupIdSelection !== 0){
				filteredProviders = this.allProvidersToDisplay.filter((provider) => provider.providerGroupId  === providerGroupIdSelection && !provider.excludeFromCalendar);
			} else {
				filteredProviders = this.allProvidersToDisplay.filter((provider) => !provider.excludeFromCalendar);
			}
			console.log(filteredProviders);
			filteredProviders.forEach((provider) => {
				this.calendarOptions.resources.push({
							id: `${provider.provider_id}`,
							title: `${provider.first_name} ${provider.last_name} (${provider.provider_id})`,
							calendarOrder: `${provider.calendarOrder}`,
						});
			});
		},
		async updateCalendarOptionsFromStore() {
			this.calendarOptions.initialView = this.$store.calendarObject.calendar.initialView;
			this.calendarOptions.slotMinTime = this.$store.calendarObject.calendar.slotMinTime;
			this.calendarOptions.slotMaxTime = this.$store.calendarObject.calendar.slotMaxTime;
			this.calendarOptions.resources = this.$store.calendarObject.calendar.resources;
			let id = this.$route.params.id;
			this.calendarOptions.events = this.$store.calendarObject.calendar.events;
			this.oldestDateLoaded = this.$store.calendarObject.oldestDateLoaded;
			this.newestDateLoaded = this.$store.calendarObject.newestDateLoaded;
			this.appointmentsRes = this.$store.calendarObject.appointmentsRes;

			if (id && this.$route.params.rnaTotalPlanned) {
				this.updateSpecificCalendarEvent(id, this.$route.params);
			}
			this.filterProviderId = this.$store.calendarObject.filterProviderId;
			this.filterProviderGroupId = this.$store.calendarObject.filterProviderGroupId;
			return true;
		},
		async goToPreviousSelectedDate() {
			console.log("going to previouslty selected event")
			let calendarApi = this.$refs.calendar.getApi();
			let calendarDate = DateHelper.getDatePortionFromString(this.$route.params.target);
			await this.updateCalendarOptionsFromStore();
			this.checkIfOutOfRange(calendarDate);
			calendarApi.gotoDate(calendarDate);
			this.adjustTimeSlot(this.addDays(calendarApi.currentData.dateProfile.activeRange.start, 1), calendarApi.currentData.dateProfile.activeRange.end, calendarApi.currentData.currentViewType);
			// console.log(this.$route.params.id)
			// document.getElementById(`${this.$route.params.id}`).scrollIntoView();
			document.querySelector("#kt_content > div > div > div > div > div > div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr > td > div").firstChild.scrollTop = this.$store.fc_scroll;

			this.$route.params = null;
		},
		updateSpecificCalendarEvent(id, params) {
			this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id).extendedProps.apptObject.rnaTotalPlanned = Number(params.rnaTotalPlanned);
			this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id).extendedProps.apptObject.totalCompleted = Number(params.newTotalCompleted);
			this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id).extendedProps.appointmentDescription = params.newReason;
			let apptIsOptimal = this.$route.params.isOptimal === 'true';
			let apptIsLateToUpdate = new Date(this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id).extendedProps.apptObject.endDate).addMinutes(7) > this.changeNowTimezone();
			console.log(apptIsOptimal, apptIsLateToUpdate);
			if (apptIsOptimal == true && apptIsLateToUpdate) {
				this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id).classNames = ["eventSuccess"];
			}
			console.log(
				"specificEvent ",
				this.calendarOptions.events.find((x) => x.extendedProps.apptObject.appointmentId == id)
			);
			console.log(this.$route.params);
		},
		async isPhoneScreen(e) {
			let innerWidth = e.target.innerWidth;
			if (innerWidth > 420) {
				// await this.takeBackToDefault_selectedProviders();
			}
		},
	},
	created() {
		window.addEventListener("resize", this.isPhoneScreen);
	},
	async mounted() {
		//i dont like this but seems to be the easiest way to center the date.
		document.getElementsByClassName("fc-toolbar-chunk")[2].style.width = 
		document.getElementsByClassName("fc-toolbar-chunk")[0].offsetWidth + "px"
		try {
			//  setup modals
			let modalElement = document.getElementById("print_work_day_plan_modal");
			this.printWorkDayPlanModal = new bootstrap.Modal(modalElement);
			//END SETUP MODALS
			
			console.log("mounted");
			if (this.$route.query.c) {
				localStorage.setItem(LOCAL_STORAGE_CONST.ClinicGuid, this.$route.query.c);
			}
			await this.getProviders();
			this.loading = true;				
			if (this.$store.getters.currentClinicInfo.useProviderGroups){
				let res = await ApiService.get("Provider/ProviderGroups");
				console.log(res)
				this.providerGroups = res.data;
				this.providerGroups.unshift({providerGroupId: 0, name: "All Groups"});
			}				
			this.loading = false;

			let calendarApi = this.$refs.calendar.getApi();
			let dateToSet = new Date(); //default is today
			let weekly = false;
			if (this.$route.query.weekly) {
				weekly = Boolean(this.$route.query.weekly);
			}
			//
			if (this.$route.params?.target) {
				await this.goToPreviousSelectedDate()
				//dateToSet = new Date(this.$route.params.target);
				//dateToSet = new Date(dateToSet.getTime() + dateToSet.getTimezoneOffset() * 60000);
				//console.log("target mark", this.$route.params.target)
			} else {
				//parse query string
				if (this.$route.query.date) {
					dateToSet = new Date(this.$route.query.date);
					dateToSet = new Date(dateToSet.getTime() + dateToSet.getTimezoneOffset() * 60000);
				} else if (this.$store.lastCalendarDate) {
					dateToSet = this.$store.lastCalendarDate;
				}
				console.log("date to set string", this.$route.query.date);
				console.log("date to set", dateToSet);

				if (weekly && calendarApi) {
					calendarApi.changeView("timeGridWeek");
					this.selectedProviderId = this.$route.query.p_id;
					this.showSelectProvider = true;
				}

				await this.changeCalendarDate(dateToSet);


				//   if (this.$route.params.target) {
				//     let tempTimeSlotsDate = new Date(this.$route.params.target);
				//     await this.changeCalendarDate({ target: { value: tempTimeSlotsDate } });
				//   }
				//   if (this.$store.lastCalendarDate) {
				//     console.log("store initiating");
				//     await this.changeCalendarDate({
				//       target: { value: this.$store.lastCalendarDate, fromStore: 1 },
				//     });
				//     await this.populateTasks(
				//       this.$store.lastCalendarDate,
				//       this.selectedProviderId
				//     );
				//     this.adjustTimeSlot(
				//       this.$store.lastCalendarDate,
				//       this.$store.lastCalendarDate,
				//       weekly ? "timeGridWeek" : "resourceTimeGridDay"
				//     );
				//   }
				//   if (this.$route.params.target && this.$store.calendarObject) {
				//     await this.goToPreviousSelectedDate();
				//   } 
				//   else {
				//     console.log("selectedid: ", this.selectedProviderId);
				//     await this.populateTasks(this.middleDate, this.selectedProviderId);
				//     this.adjustTimeSlot(
				//       this.middleDate,
				//       this.middleDate,
				//       weekly ? "timeGridWeek" : "resourceTimeGridDay"
				//     );
				//   }	
				
				await this.populateProviders(0, 0);				
			}
		} catch (e) {			
			this.toast.error(codes.error);
			throw e;
		}
	},
	setup() {
		const store = useStore();
		const clinicInfo = store.getters.currentClinicInfo;
		const currentUser = store.getters.currentUser;
		const instanceInfo = store.getters.currentInstanceInfo;

		setCurrentPageTitle("Calendar");
		return {
			clinicInfo,
			currentUser,
			instanceInfo,
		};
	},
});
</script>
<style lang="scss" scoped>
#inputdate::-webkit-inner-spin-button,
#inputdate::-webkit-calendar-picker-indicator {
	/* display: none;
    -webkit-appearance: none; */
	margin: 0;
}

#inputdate::-webkit-datetime-edit {
	display: none;
}

.phone-show {
	display: none;
}

@media only screen and (max-width: 990px) {
	.phone-show {
		display: initial;
	}
}

.fc-content {
	white-space: normal;
}

.violet {
	background: rgb(214, 210, 248);
	// border-left: 10px solid rgb(214, 210, 248);
}

.orange {
	background: rgb(255, 174, 120);
	// border-left: 10px solid rgb(214, 210, 248);
}

.grey {
	background: grey;
	// border-left: 10px solid grey;
}

.lightyellow {
	background: rgb(241, 255, 153);
	// border-left: 10px solid rgb(254, 255, 175);
}

.yellow {
	// border-left: 10px solid rgb(241, 255, 153);
	background: rgb(254, 255, 175);
}

td {
	padding: 5px !important;
}

.check {
	color: rgb(76, 203, 237);
}

hr {
	color: rgb(202, 205, 209);
}

.custom-header {
	background: rgb(18, 39, 83);
	padding-bottom: 0px !important;

	h5 {
		color: white;
	}

	.color-green {
		color: rgb(26, 171, 10);
	}

	.color-orange {
		color: rgb(237, 141, 12);
	}

	.x {
		color: white;
		font-size: 20px;
	}
}

.CustomMinutesSpan {
	width: 100%;
}

.fc-event-time,
.fc-event-title,
.fc-event-main {
	padding: 0 1px;
	white-space: normal;
}

.single-p-m-0 {
	margin: 0px;
}

.sub-p {
	margin-bottom: 0px !important;
	min-height: 2.6rem;
	max-height: 2.6rem;
	font-size: 1rem;
}

.head-p {
	margin-bottom: 0px !important;
	min-height: 2rem;
	max-height: 2.5rem;
}

table td:nth-child(2) {
	width: 100% !important;
}

table {
	width: 100% !important;
	table-layout: fixed !important;
	min-width: 100%;

	// -ms-overflow-style: none; /* for Internet Explorer, Edge */
	// scrollbar-width: none; /* for Firefox */
	tbody {
		-ms-overflow-style: none;
		/* for Internet Explorer, Edge */
		scrollbar-width: none;
		/* for Firefox */
	}

	thead {
		position: sticky;
		top: 0;
		background: white;

		tr {

			// width: 100% !important;
			th {
				// width: 100% !important;
				padding-top: 0px !important;
				padding-bottom: 1rem !important;
			}

			font-size: 13px !important;
		}
	}

	overflow-y: auto;
	max-height: 90%;
	display: flow-root;
}

p.pl-20px {
	padding-left: 10px;
}

.customSelect {
	width: 20%;
	height: 42.95px;
}

button.btn-fixed {
	width: 60px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

input[type="date"]::-webkit-calendar-picker-indicator {
	//hack for lame input date so it work normally
	cursor: pointer;
}</style>

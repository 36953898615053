<template>
  <div style="background-color: white;height: 100%;width: 100%;">
      <apexchart v-if="chartOptions"
        :width="width"
        :height="height"        
        :options="chartOptions"
        :series="series"        
      ></apexchart>
    </div>
</template>
<script>
export default {
  
  data: function() {
    return {
      type: "bar",
      height: "100%",
      width: "100%",
      series: [],
      chartOptions: null,      
    };
  },
  mounted() {
    //parse URL as chartoptions. URL is in base64 format and param is ?chart=<<base64>>
    let url = new URL(window.location.href);
    let chart = url.searchParams.get("chart");
    if (chart){
      let options = JSON.parse(atob(chart));      
      
      this.series = options.series;
      this.type = options.type;
      this.height = options.height;
      this.width = options.width;
      this.chartOptions = options.chartOptions;
    }    
  },
};
</script>

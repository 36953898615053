<template>
  <div class="row p-10 " style="height: 70vh;">
    <div class="col-12  h-80">
      <div>
        <h5 class="p-5">{{ title }}</h5>
      </div>
      <div
        class="toolbar d-flex justify-content-center"
        v-if="filters"
      >
        <div class="form-check mx-5" v-for="(fil, index) in filters" :key="index">
          <input v-if="fil.val == 1" class="form-check-input" type="radio" :name="`${title}`" :id="`${fil.name}${index}`"
          @change="$emit('filterChange', fil.val)" checked />
          <input v-else class="form-check-input" type="radio" :name="`${title}`" :id="`${fil.name}${index}`" @change="$emit('filterChange', fil.val)"/>
          
          <label class="form-check-label" for="flexRadioDefault1">
            {{ fil.name }} <i v-if="fil.note" class="fas fa-info-circle primary" style="font-size:small; vertical-align: super;color:#009EF7" :title="fil.note"></i>
          </label>
        </div>
      </div>
      <apexchart v-if="chartOptions"
        width="100%"
        height="100%"
        type="bar"
        :options="chartOptions"
        :series="series"
        :key="max"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    series: { type: Array },
    categories: { type: Array },
    title: { type: String },
    filters: {type: Array},
    firstFilterString: { type: String },
    secondFilterString: { type: String },
    startDate: { type: String },
    endDate: { type: String },
    stacked: {type: Boolean},
    horizontal: {type: Boolean, default:false},
    max: {type: Number, required: false, default: undefined},
  },
  data: function() {
    return {
      latestData: [],
      filter: "provider",
      baseChartOptions: {
        colors: ['#e6194b', '#3cb44b', '#FFFF00', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'],
          chart: {
          type: 'bar',
          stacked: this.stacked,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        noData: {
          text: "No data available for selected range",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "black",
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        xaxis: {
          type: "category",
          categories: this.categories,
        },        
      },
      chartOptions: null,
    };
  },
  watch: {    
    max: function(){
      console.log("max changed", this.max);
      if (this.max){
        if (this.baseChartOptions.yaxis){
          this.baseChartOptions.yaxis.max = this.max;
        } else {
          this.baseChartOptions.yaxis = {max: this.max};
        }        
        console.log("setting max to ", this.max);
      } else {
        delete this.baseChartOptions.yaxis.max;
        console.log("deleting max");
      }      

      this.chartOptions = JSON.parse(JSON.stringify(this.baseChartOptions));
    }
  },  
  mounted() {    
    if (this.max){
      this.baseChartOptions.yaxis = {max: this.max};      
    }
    this.baseChartOptions.xaxis = {type: "category", categories: this.categories};

    this.chartOptions = JSON.parse(JSON.stringify(this.baseChartOptions));
  },
};
</script>
<style lang="scss" scoped>
.h-80{
  height: 80%;
}
</style>
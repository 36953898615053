
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { DateHelper } from "@/Utilities/DateHelper"
export default defineComponent({
	name: "kt-widget-9",
	components: {},
	props: {
		widgetClasses: String,
	},
	data: function () {
		return {
			userFirstName: "",
			openTimeDataSeries: [] as any,
			tomorrowsOpenTimeDataSeries: [] as any,
			sevenDayAverageOpenTimeDataSeries: [] as any,
			openTimeMultiClinicChartOptions: {
				colors: ['#4363d8', '#000000'],
				chart: {
					type: 'bar',
					stacked: false,
					toolbar: {
						show: true
					},
					zoom: {
						enabled: true
					}
				},
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},
				toolbar: {
					show: true,
					offsetX: 0,
					offsetY: 0,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						reset: true,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: function(val, opt) {
      					return val + "%";
  }
				},
				markers: {
					size: 5,
				},
				noData: {
					text: "No data available for selected range",
					align: "center",
					verticalAlign: "middle",
					offsetX: 0,
					offsetY: 0,
					style: {
						color: "black",
						fontSize: "14px",
						fontFamily: undefined,
					},
				},
				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: "bottom",
					horizontalAlign: "center",
					floating: false,
					fontSize: "14px",
					fontFamily: "Helvetica, Arial",
					fontWeight: 400,
					formatter: undefined,
					inverseOrder: false,
					width: undefined,
					height: undefined,
					tooltipHoverFormatter: undefined,
					customLegendItems: [],
					offsetX: 0,
					offsetY: 0,
					labels: {
						colors: undefined,
						useSeriesColors: false,
					},
					markers: {
						width: 12,
						height: 12,
						strokeWidth: 0,
						strokeColor: "#fff",
						fillColors: undefined,
						radius: 12,
						customHTML: undefined,
						onClick: undefined,
						offsetX: 0,
						offsetY: 0,
					},
					itemMargin: {
						horizontal: 5,
						vertical: 0,
					},
					onItemClick: {
						toggleDataSeries: true,
					},
					onItemHover: {
						highlightDataSeries: true,
					},
				},
				xaxis: {
					type: "category",
				},
			}
		};
	},
	methods: {
		getPreviousMonday() {
			var prevMonday = new Date();
			prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
			return prevMonday.toISOString().split("T")[0];
		},
		get7DaysFromNow() {
			return DateHelper.getDatePortion(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
		},

	},
	async mounted() {
		const store = useStore();
		const clinicInfo = store.getters.currentClinicInfo;
		const currentUser = store.getters.currentUser;
		if (clinicInfo) {
			//this.clinicGoal = clinicInfo.goalHourlyProduction;
		}

		if (currentUser) {
			this.userFirstName = currentUser.firstName;
		}

		setCurrentPageTitle("Open Time Analysis");

		let startDate = this.getPreviousMonday();
		let endDate = this.get7DaysFromNow();
		const today = DateHelper.getDatePortion(new Date());
		const tomorrow = DateHelper.getDatePortion(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000));
		const params = {
			startDate: startDate,
			endDate: endDate,
			compare: true
		};
		console.log("hi", today, tomorrow);
		let res = await ApiService.query("Report/OpenTimeTotals", { params });
		if (res.status === 200) {
			console.log("date portion", DateHelper.getDatePortionFromString(res.data[0].date));
			let data = res.data.filter(d => d.openTime);
			DateHelper.sortArrayByProperty(data, "openTime", "desc");
			let todaySeries = data.filter(d => d.date.split("T")[0] === today).map(s => ({ x: s.clinicName, y: s.openTime }));
			let tomorrowSeries = data.filter(d => d.date.split("T")[0] === tomorrow).map(s => ({ x: s.clinicName, y: s.openTime }));	

			let sevenDayReduced = {};
			data.forEach(currentValue => {				
				let key = currentValue.clinicName;
				if (!sevenDayReduced[key]) {
					sevenDayReduced[key] = {clinicName: key, 
						totalMinutesWorked: currentValue.totalMinutesWorked, 
						totalMinutesClocked: currentValue.totalMinutesClocked };
										
    			} else {
    				sevenDayReduced[key].totalMinutesWorked += currentValue.totalMinutesWorked;
					sevenDayReduced[key].totalMinutesClocked += currentValue.totalMinutesClocked;
				}
			});

			let sevenDayAverage = Object.keys(sevenDayReduced).map(key => {
				const s  = sevenDayReduced[key];
				return { x: s.clinicName, y: Math.round(100 - (s.totalMinutesWorked * 100.0) / s.totalMinutesClocked )}});		

			console.log("sevenDayAverage", sevenDayAverage);
			DateHelper.sortArrayByProperty(sevenDayAverage as any, "y", "desc");

			this.openTimeDataSeries = [{ data: todaySeries }];			
			this.tomorrowsOpenTimeDataSeries = [{ data: tomorrowSeries }];
			this.sevenDayAverageOpenTimeDataSeries = [{ data: sevenDayAverage }];	
		} else {
			//this.toast.error(codes.error)
		}

	},
});
